const data = [
  {
    material_icon: [
      "access-point",
      "access-point-network",
      "access-point-network-off",
      "account-alert",
      "account-alert-outline",
      "account-arrow-left",
      "account-arrow-left-outline",
      "account-arrow-right",
      "account-arrow-right-outline",
      "account-badge",
      "account-badge-alert",
      "account-badge-alert-outline",
      "account-badge-horizontal",
      "account-badge-horizontal-outline",
      "account-badge-outline",
      "account-box",
      "account-box-multiple",
      "account-box-outline",
      "account-card-details",
      "account-card-details-outline",
      "account-check",
      "account-check-outline",
      "account-child",
      "account-child-circle",
      "account-circle",
      "account-circle-outline",
      "account-clock",
      "account-clock-outline",
      "account-convert",
      "account-details",
      "account-edit",
      "account-group",
      "account-group-outline",
      "account-heart",
      "account-heart-outline",
      "account-key",
      "account-key-outline",
      "account-minus",
      "account-minus-outline",
      "account-multiple",
      "account-multiple-check",
      "account-multiple-minus",
      "baseball-bat",
      "basket-fill",
      "basket-unfill",
      "basketball-hoop",
      "basketball-hoop-outline",
      "battery-10",
      "battery-10-bluetooth",
      "battery-20",
      "battery-20-bluetooth",
      "battery-30",
      "battery-30-bluetooth",
      "battery-40",
      "battery-40-bluetooth",
      "battery-50",
      "battery-50-bluetooth",
      "battery-60",
      "battery-60-bluetooth",
      "battery-70",
      "battery-70-bluetooth",
      "battery-80",
      "battery-80-bluetooth",
      "battery-90",
      "battery-90-bluetooth",
      "battery-alert",
      "battery-alert-bluetooth",
      "battery-bluetooth",
      "battery-bluetooth-variant",
      "battery-charging",
      "battery-charging-10",
      "battery-charging-100",
      "battery-charging-20",
      "battery-charging-30",
      "battery-charging-40",
      "battery-charging-50",
      "battery-charging-60",
      "battery-charging-70",
      "battery-charging-80",
      "battery-charging-90",
      "battery-charging-outline",
      "battery-charging-wireless",
      "battery-charging-wireless-10",
      "battery-charging-wireless-20",
      "battery-charging-wireless-30",
      "battery-charging-wireless-40",
      "battery-charging-wireless-50",
      "battery-charging-wireless-60",
      "battery-charging-wireless-70",
      "battery-charging-wireless-80",
      "battery-charging-wireless-90",
      "battery-charging-wireless-alert",
      "battery-charging-wireless-outline",
      "battery-minus",
      "battery-negative",
      "battery-outline",
      "battery-plus",
      "battery-positive",
      "battery-unknown",
      "battery-unknown-bluetooth",
      "beaker-outline",
      "bed-empty",
      "bell-alert",
      "bell-circle",
      "bell-circle-outline",
      "bell-off",
      "bell-off-outline",
      "bell-outline",
      "bell-plus",
      "bell-plus-outline",
      "bell-ring",
      "bell-ring-outline",
      "bell-sleep",
      "bell-sleep-outline",
      "billiards-rack",
      "black-mesa",
      "blender-software",
      "block-helper",
      "blood-bag",
      "bluetooth-audio",
      "bluetooth-connect",
      "bluetooth-off",
      "bluetooth-settings",
      "bluetooth-transfer",
      "blur-linear",
      "blur-off",
      "blur-radial",
      "bolnisi-cross",
      "bomb-off",
      "book-lock",
      "book-lock-open",
      "book-minus",
      "book-multiple",
      "book-multiple-minus",
      "book-multiple-plus",
      "book-multiple-remove",
      "book-multiple-variant",
      "book-open",
      "book-open-outline",
      "book-open-page-variant",
      "book-open-variant",
      "book-outline",
      "book-plus",
      "book-remove",
      "book-variant",
      "bookmark-check",
      "bookmark-minus",
      "bookmark-minus-outline",
      "bookmark-multiple",
      "bookmark-multiple-outline",
      "bookmark-music",
      "bookmark-off",
      "bookmark-off-outline",
      "bookmark-outline",
      "bookmark-plus",
      "card-bulleted",
      "card-bulleted-off",
      "card-bulleted-off-outline",
      "card-bulleted-outline",
      "card-bulleted-settings",
      "card-bulleted-settings-outline",
      "card-outline",
      "card-text",
      "card-text-outline",
      "cards-club",
      "cards-diamond",
      "cards-heart",
      "cards-outline",
      "cards-playing-outline",
      "cards-spade",
      "cards-variant",
      "carry-on-bag-check",
      "cart-arrow-down",
      "cart-arrow-right",
      "cart-arrow-up",
      "cart-minus",
      "cart-off",
      "cart-outline",
      "cart-plus",
      "cart-remove",
      "case-sensitive-alt",
      "cash-100",
      "cash-marker",
      "cash-multiple",
      "cash-refund",
      "cash-register",
      "cash-usd",
      "cast-connected",
      "cast-education",
      "cast-off",
      "ceiling-light",
      "cellphone-android",
      "cellphone-arrow-down",
      "cellphone-basic",
      "cellphone-dock",
      "cellphone-erase",
      "cellphone-iphone",
      "cellphone-key",
      "cellphone-link",
      "cellphone-link-off",
      "cellphone-lock",
      "cellphone-message",
      "cellphone-off",
      "cellphone-screenshot",
      "cellphone-settings",
      "cellphone-settings-variant",
      "cellphone-sound",
      "cellphone-text",
      "cellphone-wireless",
      "celtic-cross",
      "chair-school",
      "chart-arc",
      "chart-areaspline",
      "chart-bar",
      "chart-bar-stacked",
      "chart-bell-curve",
      "chart-bubble",
      "chart-donut",
      "chart-donut-variant",
      "chart-gantt",
      "chart-histogram",
      "chart-line",
      "chart-line-stacked",
      "chart-line-variant",
      "chart-multiline",
      "chart-pie",
      "chart-scatterplot-hexbin",
      "chart-timeline",
      "chat-alert",
      "chat-processing",
      "check-all",
      "check-bold",
      "check-box-multiple-outline",
      "check-box-outline",
      "check-circle",
      "check-circle-outline",
      "check-decagram",
      "check-network",
      "check-network-outline",
      "check-outline",
      "check-underline",
      "check-underline-circle",
      "check-underline-circle-outline",
      "checkbox-blank",
      "checkbox-blank-circle",
      "checkbox-blank-circle-outline",
      "checkbox-blank-outline",
      "checkbox-intermediate",
      "checkbox-marked",
      "checkbox-marked-circle",
      "checkbox-marked-circle-outline",
      "checkbox-marked-outline",
      "checkbox-multiple-blank",
      "checkbox-multiple-blank-circle",
      "checkbox-multiple-blank-circle-outline",
      "checkbox-multiple-blank-outline",
      "checkbox-multiple-marked",
      "checkbox-multiple-marked-circle",
      "checkbox-multiple-marked-circle-outline",
      "checkbox-multiple-marked-outline",
      "chef-hat",
      "chemical-weapon",
      "chess-bishop",
      "chess-king",
      "chess-knight",
      "chess-pawn",
      "chess-queen",
      "chess-rook",
      "chevron-double-down",
      "chevron-double-left",
      "chevron-double-right",
      "chevron-double-up",
      "chevron-down",
      "chevron-down-box",
      "chevron-down-box-outline",
      "chevron-down-circle",
      "chevron-down-circle-outline",
      "chevron-left",
      "chevron-left-box",
      "chevron-left-box-outline",
      "chevron-left-circle",
      "chevron-left-circle-outline",
      "chevron-right",
      "chevron-right-box",
      "chevron-right-box-outline",
      "chevron-right-circle",
      "chevron-right-circle-outline",
      "chevron-triple-down",
      "chevron-triple-left",
      "chevron-triple-right",
      "chevron-triple-up",
      "chevron-up",
      "chevron-up-box",
      "chevron-up-box-outline",
      "chevron-up-circle",
      "chevron-up-circle-outline",
      "chili-hot",
      "chili-medium",
      "chili-mild",
      "christianity-outline",
      "circle-edit-outline",
      "circle-medium",
      "circle-outline",
      "circle-slice-1",
      "circle-slice-2",
      "circle-slice-3",
      "circle-slice-4",
      "circle-slice-5",
      "circle-slice-6",
      "circle-slice-7",
      "circle-slice-8",
      "circle-small",
      "circular-saw",
      "cisco-webex",
      "city-variant",
      "city-variant-outline",
      "clipboard-account",
      "clipboard-account-outline",
      "clipboard-alert",
      "clipboard-alert-outline",
      "clipboard-arrow-down",
      "clipboard-arrow-down-outline",
      "clipboard-arrow-left",
      "clipboard-arrow-left-outline",
      "clipboard-arrow-right",
      "clipboard-arrow-right-outline",
      "clipboard-arrow-up",
      "clipboard-arrow-up-outline",
      "clipboard-check",
      "clipboard-check-outline",
      "clipboard-flow",
      "clipboard-outline",
      "clipboard-play",
      "clipboard-play-outline",
      "clipboard-plus",
      "clipboard-pulse",
      "clipboard-pulse-outline",
      "clipboard-text",
      "clipboard-text-outline",
      "clipboard-text-play",
      "clipboard-text-play-outline",
      "clock-alert",
      "clock-alert-outline",
      "clock-end",
      "clock-fast",
      "clock-in",
      "clock-out",
      "clock-outline",
      "clock-start",
      "close-box",
      "close-box-multiple",
      "close-box-multiple-outline",
      "close-box-outline",
      "close-circle",
      "close-circle-outline",
      "close-network",
      "close-network-outline",
      "close-octagon",
      "close-octagon-outline",
      "close-outline",
      "closed-caption",
      "closed-caption-outline",
      "cloud-alert",
      "cloud-braces",
      "cloud-check",
      "cloud-circle",
      "cloud-download",
      "cloud-download-outline",
      "cloud-off-outline",
      "cloud-outline",
      "cloud-print",
      "cloud-print-outline",
      "cloud-question",
      "cloud-search",
      "cloud-search-outline",
      "cloud-sync",
      "cloud-tags",
      "cloud-upload",
      "cloud-upload-outline",
      "code-array",
      "code-braces",
      "code-brackets",
      "code-equal",
      "code-greater-than",
      "code-greater-than-or-equal",
      "code-less-than",
      "code-less-than-or-equal",
      "code-not-equal",
      "code-not-equal-variant",
      "code-parentheses",
      "code-string",
      "code-tags",
      "code-tags-check",
      "coffee-outline",
      "coffee-to-go",
      "collapse-all",
      "collapse-all-outline",
      "color-helper",
      "comma-box",
      "comma-box-outline",
      "comma-circle",
      "comma-circle-outline",
      "comment-account",
      "comment-account-outline",
      "comment-alert",
      "comment-alert-outline",
      "comment-arrow-left",
      "comment-arrow-left-outline",
      "comment-arrow-right",
      "comment-arrow-right-outline",
      "comment-check",
      "comment-check-outline",
      "comment-eye",
      "comment-eye-outline",
      "comment-multiple",
      "comment-multiple-outline",
      "comment-outline",
      "comment-plus",
      "comment-plus-outline",
      "comment-processing",
      "comment-processing-outline",
      "comment-question",
      "comment-question-outline",
      "comment-remove",
      "comment-remove-outline",
      "comment-search",
      "comment-search-outline",
      "comment-text",
      "comment-text-multiple",
      "comment-text-multiple-outline",
      "comment-text-outline",
      "compass-off",
      "compass-off-outline",
      "compass-outline",
      "console-line",
      "console-network",
      "console-network-outline",
      "contact-mail",
      "contactless-payment",
      "contain-end",
      "contain-start",
      "content-copy",
      "content-cut",
      "content-duplicate",
      "content-paste",
      "content-save",
      "content-save-all",
      "content-save-edit",
      "content-save-edit-outline",
      "content-save-move",
      "content-save-move-outline",
      "content-save-outline",
      "content-save-settings",
      "content-save-settings-outline",
      "contrast-box",
      "contrast-circle",
      "controller-classic",
      "controller-classic-outline",
      "creative-commons",
      "credit-card",
      "credit-card-marker",
      "credit-card-multiple",
      "credit-card-off",
      "credit-card-plus",
      "credit-card-refund",
      "credit-card-scan",
      "credit-card-settings",
      "credit-card-wireless",
      "crop-free",
      "crop-landscape",
      "crop-portrait",
      "crop-rotate",
      "crop-square",
      "crosshairs-gps",
      "crystal-ball",
      "cube-outline",
      "cube-scan",
      "cube-send",
      "cube-unfolded",
      "cup-off",
      "cup-water",
      "currency-bdt",
      "currency-brl",
      "currency-btc",
      "currency-chf",
      "currency-cny",
      "currency-eth",
      "currency-eur",
      "currency-gbp",
      "currency-ils",
      "currency-inr",
      "currency-jpy",
      "currency-krw",
      "currency-kzt",
      "currency-ngn",
      "currency-php",
      "currency-rub",
      "currency-sign",
      "currency-try",
      "currency-twd",
      "currency-usd",
      "currency-usd-off",
      "current-ac",
      "current-dc",
      "cursor-default",
      "cursor-default-click",
      "cursor-default-click-outline",
      "cursor-default-outline",
      "cursor-move",
      "cursor-pointer",
      "cursor-text",
      "database-check",
      "database-edit",
      "database-export",
      "database-import",
      "database-lock",
      "database-minus",
      "database-plus",
      "database-refresh",
      "database-remove",
      "database-search",
      "database-settings",
      "death-star",
      "death-star-variant",
      "deathly-hallows",
      "debug-step-into",
      "debug-step-out",
      "debug-step-over",
      "decagram-outline",
      "decimal-decrease",
      "decimal-increase",
      "delete-circle",
      "delete-circle-outline",
      "delete-empty",
      "delete-forever",
      "delete-forever-outline",
      "delete-outline",
      "delete-restore",
      "delete-sweep",
      "delete-sweep-outline",
      "delete-variant",
      "desk-lamp",
      "desktop-classic",
      "desktop-mac",
      "desktop-mac-dashboard",
      "desktop-tower",
      "desktop-tower-monitor",
      "dev-to",
      "developer-board",
      "diameter-outline",
      "diameter-variant",
      "diamond-outline",
      "diamond-stone",
      "dice-1",
      "dice-2",
      "dice-3",
      "dice-4",
      "dice-5",
      "dice-6",
      "dice-d10",
      "dice-d12",
      "dice-d20",
      "dice-d4",
      "dice-d6",
      "dice-d8",
      "dice-multiple",
      "dip-switch",
      "directions-fork",
      "disc-alert",
      "disc-player",
      "disqus-outline",
      "diving-flippers",
      "diving-helmet",
      "diving-scuba",
      "diving-scuba-flag",
      "diving-scuba-tank",
      "diving-scuba-tank-multiple",
      "diving-snorkel",
      "division-box",
      "dns-outline",
      "do-not-disturb",
      "do-not-disturb-off",
      "dog-service",
      "dog-side",
      "domain-off",
      "door-closed",
      "door-open",
      "doorbell-video",
      "dot-net",
      "dots-horizontal",
      "dots-horizontal-circle",
      "dots-horizontal-circle-outline",
      "dots-vertical",
      "dots-vertical-circle",
      "dots-vertical-circle-outline",
      "download-multiple",
      "download-network",
      "download-network-outline",
      "download-outline",
      "drag-horizontal",
      "drag-variant",
      "drag-vertical",
      "drama-masks",
      "drawing-box",
      "dribbble-box",
      "dump-truck",
      "ear-hearing",
      "ear-hearing-off",
      "earth-box",
      "earth-box-off",
      "earth-off",
      "egg-easter",
      "eight-track",
      "eject-outline",
      "elevation-decline",
      "elevation-rise",
      "email-alert",
      "email-box",
      "email-check",
      "email-check-outline",
      "email-lock",
      "email-mark-as-unread",
      "email-open",
      "email-open-outline",
      "email-outline",
      "email-plus",
      "email-plus-outline",
      "email-search",
      "email-search-outline",
      "email-variant",
      "emoticon-angry",
      "emoticon-angry-outline",
      "emoticon-cool",
      "emoticon-cool-outline",
      "emoticon-cry",
      "emoticon-cry-outline",
      "emoticon-dead",
      "emoticon-dead-outline",
      "emoticon-devil",
      "emoticon-devil-outline",
      "emoticon-excited",
      "emoticon-excited-outline",
      "emoticon-happy",
      "emoticon-happy-outline",
      "emoticon-kiss",
      "emoticon-kiss-outline",
      "emoticon-neutral",
      "emoticon-neutral-outline",
      "emoticon-outline",
      "emoticon-poop",
      "emoticon-poop-outline",
      "emoticon-sad",
      "emoticon-sad-outline",
      "emoticon-tongue",
      "emoticon-tongue-outline",
      "emoticon-wink",
      "emoticon-wink-outline",
      "engine-off",
      "engine-off-outline",
      "engine-outline",
      "equal-box",
      "eraser-variant",
      "ethernet-cable",
      "ethernet-cable-off",
      "ev-station",
      "exit-run",
      "exit-to-app",
      "expand-all",
      "expand-all-outline",
      "exponent-box",
      "export-variant",
      "eye-check",
      "eye-check-outline",
      "eye-circle",
      "eye-circle-outline",
      "eye-off",
      "eye-off-outline",
      "eye-outline",
      "eye-plus",
      "eye-plus-outline",
      "eye-settings",
      "eye-settings-outline",
      "eyedropper-variant",
      "face-agent",
      "face-outline",
      "face-profile",
      "face-recognition",
      "facebook-box",
      "facebook-messenger",
      "facebook-workplace",
      "fan-off",
      "fast-forward",
      "fast-forward-10",
      "fast-forward-30",
      "fast-forward-outline",
      "feature-search",
      "feature-search-outline",
      "file-account",
      "file-alert",
      "file-alert-outline",
      "file-cabinet",
      "file-cancel",
      "file-cancel-outline",
      "file-chart",
      "file-check",
      "file-check-outline",
      "file-cloud",
      "file-compare",
      "file-delimited",
      "file-document",
      "file-document-box",
      "file-document-box-multiple",
      "file-document-box-multiple-outline",
      "file-document-box-outline",
      "file-document-edit",
      "file-document-edit-outline",
      "file-document-outline",
      "file-download",
      "file-download-outline",
      "file-excel",
      "file-excel-box",
      "file-export",
      "file-eye",
      "file-eye-outline",
      "file-find",
      "file-find-outline",
      "file-hidden",
      "file-image",
      "file-import",
      "file-lock",
      "file-move",
      "file-multiple",
      "file-music",
      "file-music-outline",
      "file-outline",
      "file-pdf",
      "file-pdf-box",
      "file-pdf-outline",
      "file-percent",
      "file-plus",
      "file-powerpoint",
      "file-powerpoint-box",
      "file-presentation-box",
      "file-question",
      "file-remove",
      "file-replace",
      "file-replace-outline",
      "file-restore",
      "file-search",
      "file-search-outline",
      "file-send",
      "file-table",
      "file-table-outline",
      "file-tree",
      "file-undo",
      "file-upload",
      "file-upload-outline",
      "file-video",
      "file-video-outline",
      "file-word",
      "file-word-box",
      "file-xml",
      "filmstrip-off",
      "filter-outline",
      "filter-remove",
      "filter-remove-outline",
      "filter-variant",
      "find-replace",
      "fire-truck",
      "fireplace-off",
      "flag-checkered",
      "flag-minus",
      "flag-outline",
      "flag-plus",
      "flag-remove",
      "flag-triangle",
      "flag-variant",
      "flag-variant-outline",
      "flash-auto",
      "flash-circle",
      "flash-off",
      "flash-outline",
      "flash-red-eye",
      "flashlight-off",
      "flask-empty",
      "flask-empty-outline",
      "flask-outline",
      "flip-to-back",
      "flip-to-front",
      "floor-lamp",
      "floor-plan",
      "floppy-variant",
      "flower-outline",
      "flower-poppy",
      "flower-tulip",
      "flower-tulip-outline",
      "folder-account",
      "folder-account-outline",
      "folder-alert",
      "folder-alert-outline",
      "folder-clock",
      "folder-clock-outline",
      "folder-download",
      "folder-edit",
      "folder-edit-outline",
      "folder-google-drive",
      "folder-image",
      "folder-key",
      "folder-key-network",
      "folder-key-network-outline",
      "folder-lock",
      "folder-lock-open",
      "folder-move",
      "folder-multiple",
      "folder-multiple-image",
      "folder-multiple-outline",
      "folder-network",
      "folder-network-outline",
      "folder-open",
      "folder-open-outline",
      "folder-outline",
      "folder-plus",
      "folder-plus-outline",
      "folder-pound",
      "folder-pound-outline",
      "folder-remove",
      "folder-remove-outline",
      "folder-search",
      "folder-search-outline",
      "folder-star",
      "folder-star-outline",
      "folder-sync",
      "folder-sync-outline",
      "folder-text",
      "folder-text-outline",
      "folder-upload",
      "font-awesome",
      "food-apple",
      "food-apple-outline",
      "food-croissant",
      "food-fork-drink",
      "food-off",
      "food-variant",
      "football-australian",
      "football-helmet",
      "format-align-bottom",
      "format-align-center",
      "format-align-justify",
      "format-align-left",
      "format-align-middle",
      "format-align-right",
      "format-align-top",
      "format-annotation-minus",
      "format-annotation-plus",
      "format-bold",
      "format-clear",
      "format-color-fill",
      "format-color-highlight",
      "format-color-text",
      "format-columns",
      "format-float-center",
      "format-float-left",
      "format-float-none",
      "format-float-right",
      "format-font",
      "format-font-size-decrease",
      "format-font-size-increase",
      "format-header-1",
      "format-header-2",
      "format-header-3",
      "format-header-4",
      "format-header-5",
      "format-header-6",
      "format-header-decrease",
      "format-header-equal",
      "format-header-increase",
      "format-header-pound",
      "format-horizontal-align-center",
      "format-horizontal-align-left",
      "format-horizontal-align-right",
      "format-indent-decrease",
      "format-indent-increase",
      "format-italic",
      "format-letter-case",
      "format-letter-case-lower",
      "format-letter-case-upper",
      "format-line-spacing",
      "format-line-style",
      "format-line-weight",
      "format-list-bulleted",
      "format-list-bulleted-square",
      "format-list-bulleted-type",
      "format-list-checkbox",
      "format-list-checks",
      "format-list-numbered",
      "format-list-numbered-rtl",
      "format-page-break",
      "format-paint",
      "format-paragraph",
      "format-pilcrow",
      "format-quote-close",
      "format-quote-open",
      "format-rotate-90",
      "format-section",
      "format-size",
      "format-strikethrough",
      "format-strikethrough-variant",
      "format-subscript",
      "format-superscript",
      "format-text",
      "format-text-rotation-down",
      "format-text-rotation-none",
      "format-text-variant",
      "format-text-wrapping-clip",
      "format-text-wrapping-overflow",
      "format-text-wrapping-wrap",
      "format-textbox",
      "format-textdirection-l-to-r",
      "format-textdirection-r-to-l",
      "format-title",
      "format-underline",
      "format-vertical-align-bottom",
      "format-vertical-align-center",
      "format-vertical-align-top",
      "format-wrap-inline",
      "format-wrap-square",
      "format-wrap-tight",
      "format-wrap-top-bottom",
      "forum-outline",
      "fountain-pen",
      "fountain-pen-tip",
      "fridge-bottom",
      "fridge-outline",
      "fridge-top",
      "fullscreen-exit",
      "function-variant",
      "fuse-blade",
      "gamepad-circle",
      "gamepad-circle-down",
      "gamepad-circle-left",
      "gamepad-circle-outline",
      "gamepad-circle-right",
      "gamepad-circle-up",
      "gamepad-down",
      "gamepad-left",
      "gamepad-right",
      "gamepad-round",
      "gamepad-round-down",
      "gamepad-round-left",
      "gamepad-round-outline",
      "gamepad-round-right",
      "gamepad-round-up",
      "gamepad-up",
      "gamepad-variant",
      "gantry-crane",
      "garage-alert",
      "garage-open",
      "gas-cylinder",
      "gas-station",
      "gate-and",
      "gate-nand",
      "gate-nor",
      "gate-not",
      "gate-or",
      "gate-xnor",
      "gate-xor",
      "gauge-empty",
      "gauge-full",
      "gauge-low",
      "gender-female",
      "gender-male",
      "gender-male-female",
      "gender-transgender",
      "gesture-double-tap",
      "gesture-pinch",
      "gesture-spread",
      "gesture-swipe",
      "gesture-swipe-down",
      "gesture-swipe-horizontal",
      "gesture-swipe-left",
      "gesture-swipe-right",
      "gesture-swipe-up",
      "gesture-swipe-vertical",
      "gesture-tap",
      "gesture-tap-hold",
      "gesture-two-double-tap",
      "gesture-two-tap",
      "ghost-off",
      "gift-outline",
      "github-box",
      "github-circle",
      "github-face",
      "glass-cocktail",
      "glass-flute",
      "glass-mug",
      "glass-stange",
      "glass-tulip",
      "glass-wine",
      "globe-model",
      "go-kart",
      "go-kart-track",
      "google-adwords",
      "google-allo",
      "google-analytics",
      "google-assistant",
      "google-cardboard",
      "google-chrome",
      "google-circles",
      "google-circles-communities",
      "google-circles-extended",
      "google-circles-group",
      "google-classroom",
      "google-controller",
      "google-controller-off",
      "google-drive",
      "google-earth",
      "google-fit",
      "google-glass",
      "google-hangouts",
      "google-home",
      "google-keep",
      "google-lens",
      "google-maps",
      "google-nearby",
      "google-pages",
      "google-photos",
      "google-physical-web",
      "google-play",
      "google-plus",
      "google-plus-box",
      "google-spreadsheet",
      "google-street-view",
      "google-translate",
      "hand-okay",
      "hand-peace",
      "hand-peace-variant",
      "hand-pointing-down",
      "hand-pointing-left",
      "hand-pointing-right",
      "hand-pointing-up",
      "hand-right",
      "hand-saw",
      "hard-hat",
      "hat-fedora",
      "hazard-lights",
      "hdr-off",
      "headphones-bluetooth",
      "headphones-box",
      "headphones-off",
      "headphones-settings",
      "headset-dock",
      "headset-off",
      "heart-box",
      "heart-box-outline",
      "heart-broken",
      "heart-broken-outline",
      "heart-circle",
      "heart-circle-outline",
      "heart-half",
      "heart-half-full",
      "heart-half-outline",
      "heart-multiple",
      "heart-multiple-outline",
      "heart-off",
      "heart-outline",
      "heart-pulse",
      "help-box",
      "help-circle",
      "help-circle-outline",
      "help-network",
      "help-network-outline",
      "help-rhombus",
      "help-rhombus-outline",
      "hexagon-multiple",
      "hexagon-outline",
      "hexagon-slice-1",
      "hexagon-slice-2",
      "hexagon-slice-3",
      "home-alert",
      "home-assistant",
      "home-automation",
      "home-circle",
      "home-city",
      "home-city-outline",
      "home-currency-usd",
      "home-floor-0",
      "home-floor-1",
      "home-floor-2",
      "home-floor-3",
      "home-floor-a",
      "home-floor-b",
      "home-floor-g",
      "home-floor-l",
      "home-floor-negative-1",
      "home-group",
      "home-heart",
      "home-lock",
      "home-lock-open",
      "home-map-marker",
      "home-minus",
      "home-modern",
      "home-outline",
      "home-plus",
      "home-variant",
      "home-variant-outline",
      "hook-off",
      "hospital-building",
      "hospital-marker",
      "hot-tub",
      "houzz-box",
      "human-child",
      "human-female",
      "human-female-boy",
      "human-female-female",
      "human-female-girl",
      "human-greeting",
      "human-handsdown",
      "human-handsup",
      "human-male",
      "human-male-boy",
      "human-male-female",
      "human-male-girl",
      "human-male-male",
      "human-pregnant",
      "humble-bundle",
      "ice-cream",
      "iframe-outline",
      "image-album",
      "image-area",
      "image-area-close",
      "image-broken",
      "image-broken-variant",
      "image-filter",
      "image-filter-black-white",
      "image-filter-center-focus",
      "image-filter-center-focus-weak",
      "image-filter-drama",
      "image-filter-frames",
      "image-filter-hdr",
      "image-filter-none",
      "image-filter-tilt-shift",
      "image-filter-vintage",
      "image-frame",
      "image-move",
      "image-multiple",
      "image-off",
      "image-outline",
      "image-plus",
      "image-search",
      "image-search-outline",
      "image-size-select-actual",
      "image-size-select-large",
      "image-size-select-small",
      "inbox-arrow-down",
      "inbox-arrow-up",
      "inbox-multiple",
      "inbox-multiple-outline",
      "information-outline",
      "information-variant",
      "internet-explorer",
      "invert-colors",
      "invert-colors-off",
      "ip-network",
      "ip-network-outline",
      "key-change",
      "key-minus",
      "key-outline",
      "key-plus",
      "key-remove",
      "key-variant",
      "keyboard-backspace",
      "keyboard-caps",
      "keyboard-close",
      "keyboard-off",
      "keyboard-off-outline",
      "keyboard-outline",
      "keyboard-return",
      "keyboard-settings",
      "keyboard-settings-outline",
      "keyboard-tab",
      "keyboard-variant",
      "knife-military",
      "label-off",
      "label-off-outline",
      "label-outline",
      "label-variant",
      "label-variant-outline",
      "lan-connect",
      "lan-disconnect",
      "lan-pending",
      "language-c",
      "language-cpp",
      "language-csharp",
      "language-css3",
      "language-go",
      "language-haskell",
      "language-html5",
      "language-java",
      "language-javascript",
      "language-lua",
      "lava-lamp",
      "layers-minus",
      "layers-off",
      "layers-off-outline",
      "layers-outline",
      "layers-plus",
      "layers-remove",
      "lead-pencil",
      "leaf-maple",
      "leak-off",
      "led-off",
      "led-on",
      "led-outline",
      "led-strip",
      "led-variant-off",
      "led-variant-on",
      "led-variant-outline",
      "less-than",
      "less-than-or-equal",
      "library-books",
      "library-movie",
      "library-music",
      "library-plus",
      "library-shelves",
      "library-video",
      "light-switch",
      "lightbulb-off",
      "lightbulb-off-outline",
      "lightbulb-on",
      "lightbulb-on-outline",
      "lightbulb-outline",
      "lighthouse-on",
      "link-box",
      "link-box-outline",
      "link-box-variant",
      "link-box-variant-outline",
      "link-off",
      "link-plus",
      "link-variant",
      "link-variant-off",
      "linkedin-box",
      "linux-mint",
      "lock-alert",
      "lock-clock",
      "lock-open",
      "lock-open-outline",
      "lock-outline",
      "lock-pattern",
      "lock-plus",
      "lock-question",
      "lock-reset",
      "lock-smart",
      "locker-multiple",
      "login-variant",
      "logout-variant",
      "magnet-on",
      "magnify-close",
      "magnify-minus",
      "magnify-minus-cursor",
      "magnify-minus-outline",
      "magnify-plus",
      "magnify-plus-cursor",
      "magnify-plus-outline",
      "mail-ru",
      "mailbox-open",
      "mailbox-open-outline",
      "mailbox-open-up",
      "mailbox-open-up-outline",
      "mailbox-outline",
      "mailbox-up",
      "mailbox-up-outline",
      "map-clock",
      "map-clock-outline",
      "map-legend",
      "map-marker",
      "map-marker-check",
      "map-marker-circle",
      "map-marker-distance",
      "map-marker-minus",
      "map-marker-multiple",
      "map-marker-off",
      "map-marker-outline",
      "map-marker-path",
      "map-marker-plus",
      "map-marker-radius",
      "map-minus",
      "map-outline",
      "map-plus",
      "map-search",
      "map-search-outline",
      "marker-cancel",
      "marker-check",
      "mastodon-variant",
      "material-design",
      "material-ui",
      "math-compass",
      "math-cos",
      "math-sin",
      "math-tan",
      "medical-bag",
      "menu-down",
      "menu-down-outline",
      "menu-left",
      "menu-left-outline",
      "menu-open",
      "menu-right",
      "menu-right-outline",
      "menu-swap",
      "menu-swap-outline",
      "menu-up",
      "menu-up-outline",
      "message-alert",
      "message-alert-outline",
      "message-bulleted",
      "message-bulleted-off",
      "message-draw",
      "message-image",
      "message-outline",
      "message-plus",
      "message-processing",
      "message-reply",
      "message-reply-text",
      "message-settings",
      "message-settings-variant",
      "message-text",
      "message-text-outline",
      "message-video",
      "metronome-tick",
      "micro-sd",
      "microphone-minus",
      "microphone-off",
      "microphone-outline",
      "microphone-plus",
      "microphone-settings",
      "microphone-variant",
      "microphone-variant-off",
      "microsoft-dynamics",
      "midi-port",
      "mini-sd",
      "minus-box",
      "minus-box-outline",
      "minus-circle",
      "minus-circle-outline",
      "minus-network",
      "minus-network-outline",
      "mixed-martial-arts",
      "mixed-reality",
      "monitor-cellphone",
      "monitor-cellphone-star",
      "monitor-dashboard",
      "monitor-lock",
      "monitor-multiple",
      "monitor-off",
      "monitor-screenshot",
      "monitor-star",
      "mother-nurse",
      "motion-sensor",
      "mouse-bluetooth",
      "mouse-off",
      "mouse-variant",
      "mouse-variant-off",
      "move-resize",
      "move-resize-variant",
      "movie-outline",
      "movie-roll",
      "multiplication-box",
      "mushroom-outline",
      "music-box",
      "music-box-outline",
      "music-circle",
      "music-circle-outline",
      "music-note",
      "music-note-bluetooth",
      "music-note-bluetooth-off",
      "music-note-eighth",
      "music-note-half",
      "music-note-off",
      "music-note-plus",
      "music-note-quarter",
      "music-note-sixteenth",
      "music-note-whole",
      "music-off",
      "nature-people",
      "near-me",
      "network-off",
      "network-off-outline",
      "network-outline",
      "network-strength-1",
      "network-strength-1-alert",
      "network-strength-2",
      "network-strength-2-alert",
      "network-strength-3",
      "network-strength-3-alert",
      "network-strength-4",
      "network-strength-4-alert",
      "network-strength-off",
      "network-strength-off-outline",
      "network-strength-outline",
      "new-box",
      "nfc-off",
      "nfc-search-variant",
      "nfc-tap",
      "nfc-variant",
      "nfc-variant-off",
      "nintendo-switch",
      "not-equal",
      "not-equal-variant",
      "note-multiple",
      "note-multiple-outline",
      "note-outline",
      "note-plus",
      "note-plus-outline",
      "note-text",
      "notebook-multiple",
      "notification-clear-all",
      "npm-variant",
      "npm-variant-outline",
      "numeric-0",
      "numeric-0-box",
      "numeric-0-box-multiple-outline",
      "numeric-0-box-outline",
      "numeric-0-circle",
      "numeric-0-circle-outline",
      "numeric-1",
      "numeric-1-box",
      "numeric-1-box-multiple-outline",
      "numeric-1-box-outline",
      "numeric-1-circle",
      "numeric-1-circle-outline",
      "numeric-2",
      "numeric-2-box",
      "numeric-2-box-multiple-outline",
      "numeric-2-box-outline",
      "numeric-2-circle",
      "numeric-2-circle-outline",
      "numeric-3",
      "numeric-3-box",
      "numeric-3-box-multiple-outline",
      "numeric-3-box-outline",
      "numeric-3-circle",
      "numeric-3-circle-outline",
      "numeric-4",
      "numeric-4-box",
      "numeric-4-box-multiple-outline",
      "numeric-4-box-outline",
      "numeric-4-circle",
      "numeric-4-circle-outline",
      "numeric-5",
      "numeric-5-box",
      "numeric-5-box-multiple-outline",
      "numeric-5-box-outline",
      "numeric-5-circle",
      "numeric-5-circle-outline",
      "numeric-6",
      "numeric-6-box",
      "numeric-6-box-multiple-outline",
      "numeric-6-box-outline",
      "numeric-6-circle",
      "numeric-6-circle-outline",
      "numeric-7",
      "numeric-7-box",
      "numeric-7-box-multiple-outline",
      "numeric-7-box-outline",
      "numeric-7-circle",
      "numeric-7-circle-outline",
      "numeric-8",
      "numeric-8-box",
      "numeric-8-box-multiple-outline",
      "numeric-8-box-outline",
      "numeric-8-circle",
      "numeric-8-circle-outline",
      "numeric-9",
      "numeric-9-box",
      "numeric-9-box-multiple-outline",
      "numeric-9-box-outline",
      "numeric-9-circle",
      "numeric-9-circle-outline",
      "numeric-9-plus-box",
      "numeric-9-plus-box-multiple-outline",
      "numeric-9-plus-box-outline",
      "numeric-9-plus-circle",
      "numeric-9-plus-circle-outline",
      "octagon-outline",
      "octagram-outline",
      "office-building",
      "oil-temperature",
      "one-up",
      "open-in-app",
      "open-in-new",
      "open-source-initiative",
      "ornament-variant",
      "pac-man",
      "package-down",
      "package-up",
      "package-variant",
      "package-variant-closed",
      "page-first",
      "page-last",
      "page-layout-body",
      "page-layout-footer",
      "page-layout-header",
      "page-layout-sidebar-left",
      "page-layout-sidebar-right",
      "page-next",
      "page-next-outline",
      "page-previous",
      "page-previous-outline",
      "palette-advanced",
      "palette-outline",
      "palette-swatch",
      "pan-bottom-left",
      "pan-bottom-right",
      "pan-down",
      "pan-horizontal",
      "pan-left",
      "pan-right",
      "pan-top-left",
      "pan-top-right",
      "pan-up",
      "pan-vertical",
      "panorama-fisheye",
      "panorama-horizontal",
      "panorama-vertical",
      "panorama-wide-angle",
      "paper-cut-vertical",
      "parachute-outline",
      "passport-biometric",
      "pause-circle",
      "pause-circle-outline",
      "pause-octagon",
      "pause-octagon-outline",
      "paw-off",
      "pdf-box",
      "pen-lock",
      "pen-minus",
      "pen-off",
      "pen-plus",
      "pen-remove",
      "pencil-box",
      "pencil-box-outline",
      "pencil-circle",
      "pencil-circle-outline",
      "pencil-lock",
      "pencil-lock-outline",
      "pencil-minus",
      "pencil-minus-outline",
      "pencil-off",
      "pencil-off-outline",
      "pencil-outline",
      "pencil-plus",
      "pencil-plus-outline",
      "pencil-remove",
      "pencil-remove-outline",
      "pentagon-outline",
      "periodic-table",
      "periodic-table-co2",
      "perspective-less",
      "perspective-more",
      "phone-bluetooth",
      "phone-classic",
      "phone-forward",
      "phone-hangup",
      "phone-in-talk",
      "phone-incoming",
      "phone-lock",
      "phone-log",
      "phone-minus",
      "phone-missed",
      "phone-off",
      "phone-outgoing",
      "phone-outline",
      "phone-paused",
      "phone-plus",
      "phone-return",
      "phone-rotate-landscape",
      "phone-rotate-portrait",
      "phone-settings",
      "phone-voip",
      "pi-box",
      "pi-hole",
      "picture-in-picture-bottom-right",
      "picture-in-picture-bottom-right-outline",
      "picture-in-picture-top-right",
      "picture-in-picture-top-right-outline",
      "pier-crane",
      "pin-off",
      "pin-off-outline",
      "pin-outline",
      "pine-tree",
      "pine-tree-box",
      "pinterest-box",
      "pinwheel-outline",
      "pipe-disconnected",
      "pipe-leak",
      "play-box-outline",
      "play-circle",
      "play-circle-outline",
      "play-network",
      "play-network-outline",
      "play-pause",
      "play-protected-content",
      "play-speed",
      "playlist-check",
      "playlist-edit",
      "playlist-minus",
      "playlist-music",
      "playlist-music-outline",
      "playlist-play",
      "playlist-plus",
      "playlist-remove",
      "playlist-star",
      "plus-box",
      "plus-box-outline",
      "plus-circle",
      "plus-circle-multiple-outline",
      "plus-circle-outline",
      "plus-minus",
      "plus-minus-box",
      "plus-network",
      "plus-network-outline",
      "plus-one",
      "plus-outline",
      "podium-bronze",
      "podium-gold",
      "podium-silver",
      "point-of-sale",
      "pokemon-go",
      "poker-chip",
      "poll-box",
      "postage-stamp",
      "pot-mix",
      "pound-box",
      "power-cycle",
      "power-off",
      "power-on",
      "power-plug",
      "power-plug-off",
      "power-settings",
      "power-sleep",
      "power-socket",
      "power-socket-au",
      "power-socket-eu",
      "power-socket-uk",
      "power-socket-us",
      "power-standby",
      "presentation-play",
      "printer-3d",
      "printer-3d-nozzle",
      "printer-3d-nozzle-outline",
      "printer-alert",
      "printer-off",
      "printer-settings",
      "printer-wireless",
      "priority-high",
      "priority-low",
      "professional-hexagon",
      "progress-alert",
      "progress-check",
      "progress-clock",
      "progress-download",
      "progress-upload",
      "progress-wrench",
      "projector-screen",
      "puzzle-outline",
      "qrcode-edit",
      "qrcode-scan",
      "quality-high",
      "quality-low",
      "quality-medium",
      "racing-helmet",
      "radiator-disabled",
      "radiator-off",
      "radio-am",
      "radio-fm",
      "radio-handheld",
      "radio-tower",
      "radiobox-blank",
      "radiobox-marked",
      "radius-outline",
      "raspberry-pi",
      "ray-end",
      "ray-end-arrow",
      "ray-start",
      "ray-start-arrow",
      "ray-start-end",
      "ray-vertex",
      "record-player",
      "record-rec",
      "rectangle-outline",
      "redo-variant",
      "reflect-horizontal",
      "reflect-vertical",
      "registered-trademark",
      "relative-scale",
      "remote-desktop",
      "rename-box",
      "reorder-horizontal",
      "reorder-vertical",
      "repeat-off",
      "repeat-once",
      "reply-all",
      "resistor-nodes",
      "resize-bottom-right",
      "restart-off",
      "restore-clock",
      "rewind-10",
      "rewind-30",
      "rewind-outline",
      "rhombus-medium",
      "rhombus-outline",
      "rhombus-split",
      "road-variant",
      "robot-industrial",
      "robot-vacuum",
      "robot-vacuum-variant",
      "roller-skate",
      "room-service",
      "room-service-outline",
      "rotate-3d",
      "rotate-left",
      "rotate-left-variant",
      "rotate-orbit",
      "rotate-right",
      "rotate-right-variant",
      "rounded-corner",
      "router-wireless",
      "router-wireless-settings",
      "rss-box",
      "ruler-square",
      "run-fast",
      "sack-percent",
      "safety-goggles",
      "satellite-uplink",
      "satellite-variant",
      "saw-blade",
      "scale-balance",
      "scale-bathroom",
      "scanner-off",
      "scissors-cutting",
      "screen-rotation",
      "screen-rotation-lock",
      "screw-flat-top",
      "screw-lag",
      "screw-machine-flat-top",
      "screw-machine-round-top",
      "screw-round-top",
      "script-outline",
      "script-text",
      "script-text-outline",
      "search-web",
      "seat-flat",
      "seat-flat-angled",
      "seat-individual-suite",
      "seat-legroom-extra",
      "seat-legroom-normal",
      "seat-legroom-reduced",
      "seat-outline",
      "seat-recline-extra",
      "seat-recline-normal",
      "security-network",
      "seed-outline",
      "select-all",
      "select-color",
      "select-compare",
      "select-drag",
      "select-inverse",
      "select-off",
      "selection-drag",
      "selection-ellipse",
      "selection-off",
      "send-circle",
      "send-circle-outline",
      "send-lock",
      "serial-port",
      "server-minus",
      "server-network",
      "server-network-off",
      "server-off",
      "server-plus",
      "server-remove",
      "server-security",
      "set-all",
      "set-center",
      "set-center-right",
      "set-left",
      "set-left-center",
      "set-left-right",
      "set-none",
      "set-right",
      "set-top-box",
      "settings-box",
      "settings-helper",
      "settings-outline",
      "shape-circle-plus",
      "shape-outline",
      "shape-plus",
      "shape-polygon-plus",
      "shape-rectangle-plus",
      "shape-square-plus",
      "share-outline",
      "share-variant",
      "shield-account",
      "shield-account-outline",
      "shield-airplane",
      "shield-airplane-outline",
      "shield-check",
      "shield-check-outline",
      "shield-cross",
      "shield-cross-outline",
      "shield-half-full",
      "shield-home",
      "shield-home-outline",
      "shield-key",
      "shield-key-outline",
      "shield-link-variant",
      "shield-link-variant-outline",
      "shield-lock",
      "shield-lock-outline",
      "shield-off",
      "shield-off-outline",
      "shield-outline",
      "shield-plus",
      "shield-plus-outline",
      "shield-remove",
      "shield-remove-outline",
      "shield-search",
      "ship-wheel",
      "shoe-formal",
      "shoe-heel",
      "shoe-print",
      "shopping-music",
      "shovel-off",
      "shower-head",
      "shuffle-disabled",
      "shuffle-variant",
      "sigma-lower",
      "sign-caution",
      "sign-direction",
      "sign-text",
      "signal-2g",
      "signal-3g",
      "signal-4g",
      "signal-5g",
      "signal-cellular-1",
      "signal-cellular-2",
      "signal-cellular-3",
      "signal-cellular-outline",
      "signal-distance-variant",
      "signal-hspa",
      "signal-hspa-plus",
      "signal-off",
      "signal-variant",
      "signature-freehand",
      "signature-image",
      "signature-text",
      "silverware-fork",
      "silverware-fork-knife",
      "silverware-spoon",
      "silverware-variant",
      "sim-alert",
      "sim-off",
      "sina-weibo",
      "skew-less",
      "skew-more",
      "skip-backward",
      "skip-forward",
      "skip-next",
      "skip-next-circle",
      "skip-next-circle-outline",
      "skip-previous",
      "skip-previous-circle",
      "skip-previous-circle-outline",
      "skull-crossbones",
      "skull-crossbones-outline",
      "skull-outline",
      "skype-business",
      "sleep-off",
      "slope-downhill",
      "slope-uphill",
      "smoke-detector",
      "smoking-off",
      "soccer-field",
      "solar-panel",
      "solar-panel-large",
      "solar-power",
      "sort-alphabetical",
      "sort-ascending",
      "sort-descending",
      "sort-numeric",
      "sort-variant",
      "sort-variant-lock",
      "sort-variant-lock-open",
      "source-branch",
      "source-commit",
      "source-commit-end",
      "source-commit-end-local",
      "source-commit-local",
      "source-commit-next-local",
      "source-commit-start",
      "source-commit-start-next-local",
      "source-fork",
      "source-merge",
      "source-pull",
      "source-repository",
      "source-repository-multiple",
      "soy-sauce",
      "spa-outline",
      "space-invaders",
      "speaker-bluetooth",
      "speaker-multiple",
      "speaker-off",
      "speaker-wireless",
      "spider-web",
      "spotlight-beam",
      "spray-bottle",
      "sprout-outline",
      "square-edit-outline",
      "square-inc",
      "square-inc-cash",
      "square-medium",
      "square-medium-outline",
      "square-outline",
      "square-root",
      "square-root-box",
      "square-small",
      "stack-exchange",
      "stack-overflow",
      "standard-definition",
      "star-box",
      "star-box-outline",
      "star-circle",
      "star-circle-outline",
      "star-face",
      "star-four-points",
      "star-four-points-outline",
      "star-half",
      "star-off",
      "star-outline",
      "star-three-points",
      "star-three-points-outline",
      "steam-box",
      "steering-off",
      "step-backward",
      "step-backward-2",
      "step-forward",
      "step-forward-2",
      "sticker-emoji",
      "stop-circle",
      "stop-circle-outline",
      "store-24-hour",
      "subdirectory-arrow-left",
      "subdirectory-arrow-right",
      "subtitles-outline",
      "subway-alert-variant",
      "subway-variant",
      "surround-sound",
      "surround-sound-2-0",
      "surround-sound-3-1",
      "surround-sound-5-1",
      "surround-sound-7-1",
      "swap-horizontal",
      "swap-horizontal-bold",
      "swap-horizontal-variant",
      "swap-vertical",
      "swap-vertical-bold",
      "swap-vertical-variant",
      "sword-cross",
      "sync-alert",
      "sync-off",
      "tab-minus",
      "tab-plus",
      "tab-remove",
      "tab-unselected",
      "table-border",
      "table-column",
      "table-column-plus-after",
      "table-column-plus-before",
      "table-column-remove",
      "table-column-width",
      "table-edit",
      "table-large",
      "table-merge-cells",
      "table-of-contents",
      "table-plus",
      "table-remove",
      "table-row",
      "table-row-height",
      "table-row-plus-after",
      "table-row-plus-before",
      "table-row-remove",
      "table-search",
      "table-settings",
      "table-tennis",
      "tablet-android",
      "tablet-cellphone",
      "tablet-ipad",
      "tag-faces",
      "tag-heart",
      "tag-heart-outline",
      "tag-minus",
      "tag-multiple",
      "tag-outline",
      "tag-plus",
      "tag-remove",
      "tag-text-outline",
      "tape-measure",
      "target-account",
      "target-variant",
      "tea-outline",
      "television-box",
      "television-classic",
      "television-classic-off",
      "television-guide",
      "television-off",
      "temperature-celsius",
      "temperature-fahrenheit",
      "temperature-kelvin",
      "tennis-ball",
      "test-tube",
      "test-tube-empty",
      "test-tube-off",
      "text-shadow",
      "text-short",
      "text-subject",
      "text-to-speech",
      "text-to-speech-off",
      "textbox-password",
      "theme-light-dark",
      "thermometer-alert",
      "thermometer-chevron-down",
      "thermometer-chevron-up",
      "thermometer-lines",
      "thermometer-minus",
      "thermometer-plus",
      "thermostat-box",
      "thought-bubble",
      "thought-bubble-outline",
      "thumb-down",
      "thumb-down-outline",
      "thumb-up",
      "thumb-up-outline",
      "thumbs-up-down",
      "ticket-account",
      "ticket-confirmation",
      "ticket-outline",
      "ticket-percent",
      "timeline-outline",
      "timeline-text",
      "timeline-text-outline",
      "timer-10",
      "timer-3",
      "timer-off",
      "timer-sand",
      "timer-sand-empty",
      "timer-sand-full",
      "toaster-oven",
      "toggle-switch",
      "toggle-switch-off",
      "toggle-switch-off-outline",
      "toggle-switch-outline",
      "toolbox-outline",
      "tooltip-account",
      "tooltip-edit",
      "tooltip-image",
      "tooltip-image-outline",
      "tooltip-outline",
      "tooltip-plus",
      "tooltip-plus-outline",
      "tooltip-text",
      "tooltip-text-outline",
      "tooth-outline",
      "tower-beach",
      "tower-fire",
      "track-light",
      "trackpad-lock",
      "traffic-light",
      "train-car",
      "train-variant",
      "transcribe-close",
      "transfer-down",
      "transfer-left",
      "transfer-right",
      "transfer-up",
      "transit-connection",
      "transit-connection-variant",
      "transit-transfer",
      "transition-masked",
      "translate-off",
      "transmission-tower",
      "trash-can",
      "trash-can-outline",
      "treasure-chest",
      "tree-outline",
      "trending-down",
      "trending-neutral",
      "trending-up",
      "triangle-outline",
      "trophy-award",
      "trophy-broken",
      "trophy-outline",
      "trophy-variant",
      "trophy-variant-outline",
      "truck-check",
      "truck-delivery",
      "truck-fast",
      "truck-trailer",
      "tshirt-crew",
      "tshirt-crew-outline",
      "tshirt-v",
      "tshirt-v-outline",
      "tumble-dryer",
      "tumblr-box",
      "tumblr-reblog",
      "tune-vertical",
      "turnstile-outline",
      "twitter-box",
      "twitter-circle",
      "twitter-retweet",
      "two-factor-authentication",
      "ultra-high-definition",
      "umbrella-closed",
      "umbrella-outline",
      "undo-variant",
      "unfold-less-horizontal",
      "unfold-less-vertical",
      "unfold-more-horizontal",
      "unfold-more-vertical",
      "upload-multiple",
      "upload-network",
      "upload-network-outline",
      "upload-outline",
      "van-passenger",
      "van-utility",
      "vector-arrange-above",
      "vector-arrange-below",
      "vector-bezier",
      "vector-circle",
      "vector-circle-variant",
      "vector-combine",
      "vector-curve",
      "vector-difference",
      "vector-difference-ab",
      "vector-difference-ba",
      "vector-ellipse",
      "vector-intersection",
      "vector-line",
      "vector-point",
      "vector-polygon",
      "vector-polyline",
      "vector-radius",
      "vector-rectangle",
      "vector-selection",
      "vector-square",
      "vector-triangle",
      "vector-union",
      "vibrate-off",
      "video-3d",
      "video-4k-box",
      "video-account",
      "video-image",
      "video-input-antenna",
      "video-input-component",
      "video-input-hdmi",
      "video-input-svideo",
      "video-minus",
      "video-off",
      "video-off-outline",
      "video-outline",
      "video-plus",
      "video-stabilization",
      "video-switch",
      "video-vintage",
      "view-agenda",
      "view-array",
      "view-carousel",
      "view-column",
      "view-comfy",
      "view-compact",
      "view-compact-outline",
      "view-dashboard",
      "view-dashboard-outline",
      "view-dashboard-variant",
      "view-day",
      "view-grid",
      "view-headline",
      "view-list",
      "view-module",
      "view-parallel",
      "view-quilt",
      "view-sequential",
      "view-split-horizontal",
      "view-split-vertical",
      "view-stream",
      "view-week",
      "virtual-reality",
      "visual-studio",
      "visual-studio-code",
      "vk-box",
      "vk-circle",
      "volume-high",
      "volume-low",
      "volume-medium",
      "volume-minus",
      "volume-mute",
      "volume-off",
      "volume-plus",
      "volume-variant-off",
      "vote-outline",
      "wall-sconce",
      "wall-sconce-flat",
      "wall-sconce-variant",
      "wallet-giftcard",
      "wallet-membership",
      "wallet-outline",
      "wallet-travel",
      "washing-machine",
      "watch-export",
      "watch-export-variant",
      "watch-import",
      "watch-import-variant",
      "watch-variant",
      "watch-vibrate",
      "watch-vibrate-off",
      "water-off",
      "water-outline",
      "water-percent",
      "water-pump",
      "weather-cloudy",
      "weather-cloudy-arrow-right",
      "weather-fog",
      "weather-hail",
      "weather-hurricane",
      "weather-lightning",
      "weather-lightning-rainy",
      "weather-night",
      "weather-partlycloudy",
      "weather-pouring",
      "weather-rainy",
      "weather-snowy",
      "weather-snowy-rainy",
      "weather-sunny",
      "weather-sunset",
      "weather-sunset-down",
      "weather-sunset-up",
      "weather-windy",
      "weather-windy-variant",
      "weight-gram",
      "weight-kilogram",
      "weight-pound",
      "wheelchair-accessibility",
      "white-balance-auto",
      "white-balance-incandescent",
      "white-balance-iridescent",
      "white-balance-sunny",
      "wifi-off",
      "wifi-star",
      "wifi-strength-1",
      "wifi-strength-1-alert",
      "wifi-strength-1-lock",
      "wifi-strength-2",
      "wifi-strength-2-alert",
      "wifi-strength-2-lock",
      "wifi-strength-3",
      "wifi-strength-3-alert",
      "wifi-strength-3-lock",
      "wifi-strength-4",
      "wifi-strength-4-alert",
      "wifi-strength-4-lock",
      "wifi-strength-alert-outline",
      "wifi-strength-lock-outline",
      "wifi-strength-off",
      "wifi-strength-off-outline",
      "wifi-strength-outline",
      "wind-turbine",
      "window-close",
      "window-closed",
      "window-maximize",
      "window-minimize",
      "window-open",
      "window-restore",
      "windows-classic",
      "wiper-wash",
      "wrap-disabled",
      "wrench-outline",
      "xamarin-outline",
      "xbox-controller",
      "xbox-controller-battery-alert",
      "xbox-controller-battery-charging",
      "xbox-controller-battery-empty",
      "xbox-controller-battery-full",
      "xbox-controller-battery-low",
      "xbox-controller-battery-medium",
      "xbox-controller-battery-unknown",
      "xbox-controller-menu",
      "xbox-controller-off",
      "xbox-controller-view",
      "xing-box",
      "xing-circle",
      "yin-yang",
      "youtube-creator-studio",
      "youtube-gaming",
      "youtube-subscription",
      "youtube-tv",
      "z-wave",
      "zip-box",
      "zip-disk",
      "zodiac-aquarius",
      "zodiac-aries",
      "zodiac-cancer",
      "zodiac-capricorn",
      "zodiac-gemini",
      "zodiac-leo",
      "zodiac-libra",
      "zodiac-pisces",
      "zodiac-sagittarius",
      "zodiac-scorpio",
      "zodiac-taurus",
      "zodiac-virgo",
    ],
  },
];
export default data;
