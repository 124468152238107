export class ConfigDB {
  static data = {
    settings: {
      layout_type: "ltr",
      sidebar: {
        type: "default",
      },
    },
    color: {
      layout_version: "light",
      color: "color-1",
      primary_color: "#7e37d8",
      secondary_color: "#fe80b2",
      mix_layout: "color-only",
    },

    router_animation: "fade",
  };
}

export default ConfigDB;
