const data = [
  {
    abstract: [
      "angry-monster",
      "bathtub",
      "bird-wings",
      "bow",
      "brain-alt",
      "butterfly-alt",
      "castle",
      "circuit",
      "dart",
      "dice-alt",
      "disability-race",
      "diving-goggle",
      "fire-alt",
      "flame-torch",
      "flora",
      "flora-flower",
      "gift-box",
      "halloween-pumpkin",
      "hand-power",
      "hand-thunder",
      "king-crown",
      "king-monster",
      "love",
      "magician-hat",
      "native-american",
      "open-eye",
      "owl-look",
      "phoenix",
      "queen-crown",
      "robot-face",
      "sand-clock",
      "shield-alt",
      "ship-wheel",
      "skull-danger",
      "skull-face",
      "snail",
      "snow-alt",
      "snow-flake",
      "snowmobile",
      "space-shuttle",
      "star-shape",
      "swirl",
      "tattoo-wing",
      "throne",
      "touch",
      "tree-alt",
      "triangle",
      "unity-hand",
      "weed",
      "woman-bird",
    ],
    animal: [
      "animal-bat",
      "animal-bear",
      "animal-bear-tracks",
      "animal-bird",
      "animal-bird-alt",
      "animal-bone",
      "animal-bull",
      "animal-camel",
      "animal-camel-alt",
      "animal-camel-head",
      "animal-cat",
      "animal-cat-alt-1",
      "animal-cat-alt-2",
      "animal-cat-alt-3",
      "animal-cat-alt-4",
      "animal-cat-with-dog",
      "animal-cow",
      "animal-cow-head",
      "animal-crab",
      "animal-crocodile",
      "animal-deer-head",
      "animal-dog",
      "animal-dog-alt",
      "animal-dog-barking",
      "animal-dolphin",
      "animal-duck-tracks",
      "animal-eagle-head",
      "animal-eaten-fish",
      "animal-elephant",
      "animal-elephant-alt",
      "animal-elephant-head",
      "animal-elephant-head-alt",
      "animal-elk",
      "animal-fish",
      "animal-fish-alt-1",
      "animal-fish-alt-2",
      "animal-fish-alt-3",
      "animal-fish-alt-4",
      "animal-fox",
      "animal-fox-alt",
      "animal-frog",
      "animal-frog-tracks",
      "animal-froggy",
      "animal-giraffe",
      "animal-giraffe-alt",
      "animal-goat-head",
      "animal-goat-head-alt-1",
      "animal-goat-head-alt-2",
      "animal-gorilla",
      "animal-hen-tracks",
      "animal-horse-head",
      "animal-horse-head-alt-1",
      "animal-horse-head-alt-2",
      "animal-horse-tracks",
      "animal-jellyfish",
      "animal-kangaroo",
      "animal-lemur",
      "animal-lion",
      "animal-lion-alt",
      "animal-lion-head",
      "animal-lion-head-alt",
      "animal-monkey",
      "animal-monkey-alt-1",
      "animal-monkey-alt-2",
      "animal-monkey-alt-3",
      "animal-octopus",
      "animal-octopus-alt",
      "animal-owl",
      "animal-panda",
      "animal-panda-alt",
      "animal-panther",
      "animal-parrot",
      "animal-parrot-lip",
      "animal-paw",
      "animal-pelican",
      "animal-penguin",
      "animal-pig",
      "animal-pig-alt",
      "animal-pigeon",
      "animal-pigeon-alt",
      "animal-pigeons",
      "animal-rabbit-running",
      "animal-rat-alt",
      "animal-rhino",
      "animal-rhino-head",
      "animal-rooster",
      "animal-seahorse",
      "animal-seal",
      "animal-shrimp",
      "animal-snail",
      "animal-snail-alt-1",
      "animal-snail-alt-2",
      "animal-snake",
      "animal-squid",
      "animal-squirrel",
      "animal-tiger",
      "animal-tiger-alt",
      "animal-turtle",
      "animal-whale",
      "animal-woodpecker",
      "animal-zebra",
    ],
    brand: [
      "brand-acer",
      "brand-adidas",
      "brand-adobe",
      "brand-air-new-zealand",
      "brand-airbnb",
      "brand-aircell",
      "brand-airtel",
      "brand-alcatel",
      "brand-alibaba",
      "brand-aliexpress",
      "brand-alipay",
      "brand-amazon",
      "brand-amd",
      "brand-american-airlines",
      "brand-android",
      "brand-android-robot",
      "brand-aol",
      "brand-apple",
      "brand-appstore",
      "brand-asus",
      "brand-ati",
      "brand-att",
      "brand-audi",
      "brand-axiata",
      "brand-bada",
      "brand-bbc",
      "brand-bing",
      "brand-blackberry",
      "brand-bmw",
      "brand-box",
      "brand-burger-king",
      "brand-business-insider",
      "brand-buzzfeed",
      "brand-cannon",
      "brand-casio",
      "brand-china-mobile",
      "brand-china-telecom",
      "brand-china-unicom",
      "brand-cisco",
      "brand-citibank",
      "brand-cnet",
      "brand-cnn",
      "brand-cocal-cola",
      "brand-compaq",
      "brand-copy",
      "brand-debian",
      "brand-delicious",
      "brand-dell",
      "brand-designbump",
      "brand-designfloat",
      "brand-disney",
      "brand-dodge",
      "brand-dove",
      "brand-ebay",
      "brand-eleven",
      "brand-emirates",
      "brand-espn",
      "brand-etihad-airways",
      "brand-etisalat",
      "brand-etsy",
      "brand-facebook",
      "brand-fastrack",
      "brand-fedex",
      "brand-ferrari",
      "brand-fitbit",
      "brand-flikr",
      "brand-forbes",
      "brand-foursquare",
      "brand-fox",
      "brand-foxconn",
      "brand-fujitsu",
      "brand-general-electric",
      "brand-gillette",
      "brand-gizmodo",
      "brand-gnome",
      "brand-google",
      "brand-gopro",
      "brand-gucci",
      "brand-hallmark",
      "brand-hi5",
      "brand-honda",
      "brand-hp",
      "brand-hsbc",
      "brand-htc",
      "brand-huawei",
      "brand-hulu",
      "brand-hyundai",
      "brand-ibm",
      "brand-icofont",
      "brand-icq",
      "brand-ikea",
      "brand-imdb",
      "brand-indiegogo",
      "brand-intel",
      "brand-ipair",
      "brand-jaguar",
      "brand-java",
      "brand-joomla",
      "brand-joomshaper",
      "brand-kickstarter",
      "brand-kik",
      "brand-lastfm",
      "brand-lego",
      "brand-lenovo",
      "brand-levis",
      "brand-lexus",
      "brand-lg",
      "brand-life-hacker",
      "brand-line-messenger",
      "brand-linkedin",
      "brand-linux",
      "brand-linux-mint",
      "brand-lionix",
      "brand-live-messenger",
      "brand-loreal",
      "brand-louis-vuitton",
      "brand-mac-os",
      "brand-marvel-app",
      "brand-mashable",
      "brand-mazda",
      "brand-mcdonals",
      "brand-mercedes",
      "brand-micromax",
      "brand-microsoft",
      "brand-mobileme",
      "brand-mobily",
      "brand-motorola",
      "brand-msi",
      "brand-mts",
      "brand-myspace",
      "brand-mytv",
      "brand-nasa",
      "brand-natgeo",
      "brand-nbc",
      "brand-nescafe",
      "brand-nestle",
      "brand-netflix",
      "brand-nexus",
      "brand-nike",
      "brand-nokia",
      "brand-nvidia",
      "brand-omega",
      "brand-opensuse",
      "brand-oracle",
      "brand-panasonic",
      "brand-paypal",
      "brand-pepsi",
      "brand-philips",
      "brand-pizza-hut",
      "brand-playstation",
      "brand-puma",
      "brand-qatar-air",
      "brand-qvc",
      "brand-readernaut",
      "brand-redbull",
      "brand-reebok",
      "brand-reuters",
      "brand-samsung",
      "brand-sap",
      "brand-saudia-airlines",
      "brand-scribd",
      "brand-shell",
      "brand-siemens",
      "brand-sk-telecom",
      "brand-slideshare",
      "brand-smashing-magazine",
      "brand-snapchat",
      "brand-sony",
      "brand-sony-ericsson",
      "brand-soundcloud",
      "brand-sprint",
      "brand-squidoo",
      "brand-starbucks",
      "brand-stc",
      "brand-steam",
      "brand-suzuki",
      "brand-symbian",
      "brand-t-mobile",
      "brand-tango",
      "brand-target",
      "brand-tata-indicom",
      "brand-techcrunch",
      "brand-telenor",
      "brand-teliasonera",
      "brand-tesla",
      "brand-the-verge",
      "brand-thenextweb",
      "brand-toshiba",
      "brand-toyota",
      "brand-tribenet",
      "brand-ubuntu",
      "brand-unilever",
      "brand-vaio",
      "brand-verizon",
      "brand-viber",
      "brand-vodafone",
      "brand-volkswagen",
      "brand-walmart",
      "brand-warnerbros",
      "brand-whatsapp",
      "brand-wikipedia",
      "brand-windows",
      "brand-wire",
      "brand-wordpress",
      "brand-xiaomi",
      "brand-yahoobuzz",
      "brand-yamaha",
      "brand-youtube",
      "brand-zain",
      "social-envato",
    ],
    business: [
      "bank-alt",
      "barcode",
      "basket",
      "bill-alt",
      "billboard",
      "briefcase-alt-1",
      "briefcase-alt-2",
      "building-alt",
      "businessman",
      "businesswoman",
      "cart-alt",
      "chair",
      "clip",
      "coins",
      "company",
      "contact-add",
      "deal",
      "files",
      "growth",
      "id-card",
      "idea",
      "list",
      "meeting-add",
      "money-bag",
      "people",
      "pie-chart",
      "presentation-alt",
      "stamp",
      "stock-mobile",
      "support",
      "tasks-alt",
      "wheel",
    ],
    chart: [
      "chart-arrows-axis",
      "chart-bar-graph",
      "chart-flow",
      "chart-flow-alt-1",
      "chart-flow-alt-2",
      "chart-histogram",
      "chart-histogram-alt",
      "chart-line",
      "chart-line-alt",
      "chart-pie",
      "chart-pie-alt",
      "chart-radar-graph",
    ],
    construction: [
      "architecture",
      "architecture-alt",
      "barricade",
      "bricks",
      "calculations",
      "cement-mix",
      "cement-mixer",
      "danger-zone",
      "drill",
      "eco-energy",
      "eco-environmen",
      "energy-air",
      "energy-oil",
      "energy-savings",
      "energy-solar",
      "energy-water",
      "engineer",
      "fire-extinguisher-alt",
      "fix-tools",
      "glue-oil",
      "hammer-alt",
      "help-robot",
      "industries",
      "industries-alt-1",
      "industries-alt-2",
      "industries-alt-3",
      "industries-alt-4",
      "industries-alt-5",
      "labour",
      "mining",
      "paint-brush",
      "pollution",
      "power-zone",
      "radio-active",
      "recycle-alt",
      "recycling-man",
      "safety-hat",
      "safety-hat-light",
      "saw",
      "screw-driver",
      "settings-alt",
      "tools-alt-1",
      "tools-alt-2",
      "tools-bag",
      "trolley",
      "trowel",
      "under-construction",
      "under-construction-alt",
      "vehicle-cement",
      "vehicle-crane",
      "vehicle-delivery-van",
      "vehicle-dozer",
      "vehicle-excavator",
      "vehicle-trucktor",
      "vehicle-wrecking",
      "worker",
      "worker-group",
      "wrench",
    ],
    currency: [
      "cur-afghani",
      "cur-afghani-false",
      "cur-afghani-minus",
      "cur-afghani-plus",
      "cur-afghani-true",
      "cur-baht",
      "cur-baht-false",
      "cur-baht-minus",
      "cur-baht-plus",
      "cur-baht-true",
      "cur-bitcoin",
      "cur-bitcoin-false",
      "cur-bitcoin-minus",
      "cur-bitcoin-plus",
      "cur-bitcoin-true",
      "cur-dollar",
      "cur-dollar-flase",
      "cur-dollar-minus",
      "cur-dollar-plus",
      "cur-dollar-true",
      "cur-dong",
      "cur-dong-false",
      "cur-dong-minus",
      "cur-dong-plus",
      "cur-dong-true",
      "cur-euro",
      "cur-euro-false",
      "cur-euro-minus",
      "cur-euro-plus",
      "cur-euro-true",
      "cur-frank",
      "cur-frank-false",
      "cur-frank-minus",
      "cur-frank-plus",
      "cur-frank-true",
      "cur-hryvnia",
      "cur-hryvnia-false",
      "cur-hryvnia-minus",
      "cur-hryvnia-plus",
      "cur-hryvnia-true",
      "cur-lira",
      "cur-lira-false",
      "cur-lira-minus",
      "cur-lira-plus",
      "cur-lira-true",
      "cur-peseta",
      "cur-peseta-false",
      "cur-peseta-minus",
      "cur-peseta-plus",
      "cur-peseta-true",
      "cur-peso",
      "cur-peso-false",
      "cur-peso-minus",
      "cur-peso-plus",
      "cur-peso-true",
      "cur-pound",
      "cur-pound-false",
      "cur-pound-minus",
      "cur-pound-plus",
      "cur-pound-true",
      "cur-renminbi",
      "cur-renminbi-false",
      "cur-renminbi-minus",
      "cur-renminbi-plus",
      "cur-renminbi-true",
      "cur-riyal",
      "cur-riyal-false",
      "cur-riyal-minus",
      "cur-riyal-plus",
      "cur-riyal-true",
      "cur-rouble",
      "cur-rouble-false",
      "cur-rouble-minus",
      "cur-rouble-plus",
      "cur-rouble-true",
      "cur-rupee",
      "cur-rupee-false",
      "cur-rupee-minus",
      "cur-rupee-plus",
      "cur-rupee-true",
      "cur-taka",
      "cur-taka-false",
      "cur-taka-minus",
      "cur-taka-plus",
      "cur-taka-true",
      "cur-turkish-lira",
      "cur-turkish-lira-false",
      "cur-turkish-lira-minus",
      "cur-turkish-lira-plus",
      "cur-turkish-lira-true",
      "cur-won",
      "cur-won-false",
      "cur-won-minus",
      "cur-won-plus",
      "cur-won-true",
      "cur-yen",
      "cur-yen-false",
      "cur-yen-minus",
      "cur-yen-plus",
      "cur-yen-true",
    ],
    device: [
      "android-nexus",
      "android-tablet",
      "apple-watch",
      "drwaing-tablet",
      "earphone",
      "flash-drive",
      "game-control",
      "headphone-alt",
      "htc-one",
      "imac",
      "ipad-touch",
      "iphone",
      "ipod-nano",
      "ipod-touch",
      "keyboard-alt",
      "keyboard-wireless",
      "laptop-alt",
      "macbook",
      "magic-mouse",
      "microphone-alt",
      "monitor",
      "mouse",
      "nintendo",
      "playstation",
      "psvita",
      "radio-mic",
      "refrigerator",
      "samsung-galaxy",
      "surface-tablet",
      "washing-machine",
      "wifi-router",
      "wii-u",
      "windows-lumia",
      "wireless-mouse",
      "xbox-360",
    ],
    directional: [
      "arrow-down",
      "arrow-left",
      "arrow-right",
      "arrow-up",
      "block-down",
      "block-left",
      "block-right",
      "block-up",
      "bubble-down",
      "bubble-left",
      "bubble-right",
      "bubble-up",
      "caret-down",
      "caret-left",
      "caret-right",
      "caret-up",
      "circled-down",
      "circled-left",
      "circled-right",
      "circled-up",
      "collapse",
      "cursor-drag",
      "curved-double-left",
      "curved-double-right",
      "curved-down",
      "curved-left",
      "curved-right",
      "curved-up",
      "dotted-down",
      "dotted-left",
      "dotted-right",
      "dotted-up",
      "double-left",
      "double-right",
      "drag",
      "drag1",
      "drag2",
      "drag3",
      "expand-alt",
      "hand-down",
      "hand-drag",
      "hand-drag1",
      "hand-drag2",
      "hand-drawn-alt-down",
      "hand-drawn-alt-left",
      "hand-drawn-alt-right",
      "hand-drawn-alt-up",
      "hand-drawn-down",
      "hand-drawn-left",
      "hand-drawn-right",
      "hand-drawn-up",
      "hand-left",
      "hand-right",
      "hand-up",
      "line-block-down",
      "line-block-left",
      "line-block-right",
      "line-block-up",
      "long-arrow-down",
      "long-arrow-left",
      "long-arrow-right",
      "long-arrow-up",
      "rounded-collapse",
      "rounded-double-left",
      "rounded-double-right",
      "rounded-down",
      "rounded-expand",
      "rounded-left",
      "rounded-left-down",
      "rounded-left-up",
      "rounded-right",
      "rounded-right-down",
      "rounded-right-up",
      "rounded-up",
      "scroll-bubble-down",
      "scroll-bubble-left",
      "scroll-bubble-right",
      "scroll-bubble-up",
      "scroll-double-down",
      "scroll-double-left",
      "scroll-double-right",
      "scroll-double-up",
      "scroll-down",
      "scroll-left",
      "scroll-long-down",
      "scroll-long-left",
      "scroll-long-right",
      "scroll-long-up",
      "scroll-right",
      "scroll-up",
      "simple-down",
      "simple-left",
      "simple-left-down",
      "simple-left-up",
      "simple-right",
      "simple-right-down",
      "simple-right-up",
      "simple-up",
      "square-down",
      "square-left",
      "square-right",
      "square-up",
      "stylish-down",
      "stylish-left",
      "stylish-right",
      "stylish-up",
      "swoosh-down",
      "swoosh-left",
      "swoosh-right",
      "swoosh-up",
      "thin-double-left",
      "thin-double-right",
      "thin-down",
      "thin-left",
      "thin-right",
      "thin-up",
    ],
    eduction: [
      "atom",
      "award",
      "bell-alt",
      "book-alt",
      "brainstorming",
      "certificate-alt-1",
      "certificate-alt-2",
      "dna-alt-2",
      "education",
      "electron",
      "fountain-pen",
      "globe-alt",
      "graduate-alt",
      "group-students",
      "hat",
      "hat-alt",
      "instrument",
      "lamp-light",
      "microscope-alt",
      "paper",
      "pen-alt-4",
      "pen-nib",
      "pencil-alt-5",
      "quill-pen",
      "read-book",
      "read-book-alt",
      "school-bag",
      "school-bus",
      "student",
      "student-alt",
      "teacher",
      "test-bulb",
      "test-tube-alt",
      "university",
    ],
    emotion: [
      "emo-angry",
      "emo-astonished",
      "emo-confounded",
      "emo-confused",
      "emo-crying",
      "emo-dizzy",
      "emo-expressionless",
      "emo-heart-eyes",
      "emo-laughing",
      "emo-nerd-smile",
      "emo-open-mouth",
      "emo-rage",
      "emo-rolling-eyes",
      "emo-sad",
      "emo-simple-smile",
      "emo-slightly-smile",
      "emo-smirk",
      "emo-stuck-out-tongue",
      "emo-wink-smile",
      "emo-worried",
    ],
    file: [
      "file-audio",
      "file-avi-mp4",
      "file-bmp",
      "file-code",
      "file-css",
      "file-document",
      "file-eps",
      "file-excel",
      "file-exe",
      "file-file",
      "file-flv",
      "file-gif",
      "file-html5",
      "file-image",
      "file-iso",
      "file-java",
      "file-javascript",
      "file-jpg",
      "file-midi",
      "file-mov",
      "file-mp3",
      "file-pdf",
      "file-php",
      "file-png",
      "file-powerpoint",
      "file-presentation",
      "file-psb",
      "file-psd",
      "file-python",
      "file-ruby",
      "file-spreadsheet",
      "file-sql",
      "file-svg",
      "file-text",
      "file-tiff",
      "file-video",
      "file-wave",
      "file-wmv",
      "file-word",
      "file-zip",
    ],
    food: [
      "apple",
      "arabian-coffee",
      "artichoke",
      "asparagus",
      "avocado",
      "baby-food",
      "banana",
      "bbq",
      "beans",
      "beer",
      "bell-pepper-capsicum",
      "birthday-cake",
      "bread",
      "broccoli",
      "burger",
      "cabbage",
      "carrot",
      "cauli-flower",
      "cheese",
      "chef",
      "cherry",
      "chicken",
      "chicken-fry",
      "cocktail",
      "coconut",
      "coffee-alt",
      "coffee-mug",
      "coffee-pot",
      "cola",
      "corn",
      "croissant",
      "crop-plant",
      "cucumber",
      "cup-cake",
      "dining-table",
      "donut",
      "egg-plant",
      "egg-poached",
      "farmer",
      "farmer1",
      "fast-food",
      "fish",
      "food-basket",
      "food-cart",
      "fork-and-knife",
      "french-fries",
      "fresh-juice",
      "fruits",
      "grapes",
      "honey",
      "hot-dog",
      "hotel-alt",
      "ice-cream",
      "ice-cream-alt",
      "ketchup",
      "kiwi",
      "layered-cake",
      "lemon-alt",
      "lobster",
      "mango",
      "milk",
      "mushroom",
      "noodles",
      "onion",
      "orange",
      "pear",
      "peas",
      "pepper",
      "pie-alt",
      "pineapple",
      "pizza",
      "pizza-slice",
      "plant",
      "popcorn",
      "potato",
      "pumpkin",
      "raddish",
      "restaurant",
      "restaurant-menu",
      "salt-and-pepper",
      "sandwich",
      "sausage",
      "shrimp",
      "sof-drinks",
      "soup-bowl",
      "spoon-and-fork",
      "steak",
      "strawberry",
      "sub-sandwich",
      "sushi",
      "taco",
      "tea",
      "tea-pot",
      "tomato",
      "waiter-alt",
      "watermelon",
      "wheat",
    ],
    kids: [
      "abc",
      "baby-cloth",
      "baby-milk-bottle",
      "baby-trolley",
      "back-pack",
      "candy",
      "cycling",
      "holding-hands",
      "infant-nipple",
      "kids-scooter",
      "safety-pin",
      "teddy-bear",
      "toy-ball",
      "toy-cat",
      "toy-duck",
      "toy-elephant",
      "toy-hand",
      "toy-horse",
      "toy-lattu",
      "toy-train",
      "unique-idea",
    ],
    law: [
      "bag-alt",
      "burglar",
      "cannon-firing",
      "cc-camera",
      "cop",
      "cop-badge",
      "court",
      "court-hammer",
      "finger-print",
      "handcuff",
      "handcuff-alt",
      "investigation",
      "investigator",
      "jail",
      "judge",
      "law",
      "law-alt-1",
      "law-alt-2",
      "law-alt-3",
      "law-book",
      "law-document",
      "lawyer",
      "lawyer-alt-1",
      "lawyer-alt-2",
      "order",
      "pistol",
      "police",
      "police-badge",
      "police-cap",
      "police-car-alt-1",
      "police-car-alt-2",
      "police-hat",
      "police-van",
      "protect",
      "scales",
      "thief",
      "thief-alt",
    ],
    mathematical: [
      "abacus",
      "abacus-alt",
      "angle",
      "calculator-alt-1",
      "calculator-alt-2",
      "circle-ruler",
      "circle-ruler-alt",
      "compass-alt-1",
      "compass-alt-2",
      "compass-alt-3",
      "compass-alt-4",
      "degrees",
      "degrees-alt-1",
      "degrees-alt-2",
      "golden-ratio",
      "marker-alt-1",
      "marker-alt-2",
      "marker-alt-3",
      "mathematical",
      "mathematical-alt-1",
      "mathematical-alt-2",
      "pen-alt-1",
      "pen-alt-2",
      "pen-alt-3",
      "pen-holder",
      "pen-holder-alt-1",
      "pencil-alt-1",
      "pencil-alt-2",
      "pencil-alt-3",
      "pencil-alt-4",
      "ruler",
      "ruler-alt-1",
      "ruler-alt-2",
      "ruler-compass",
      "ruler-compass-alt",
      "ruler-pencil",
      "ruler-pencil-alt-1",
      "ruler-pencil-alt-2",
      "rulers",
      "rulers-alt",
      "square-root",
    ],
    medical: [
      "aids",
      "ambulance",
      "autism",
      "bandage",
      "bed-patient",
      "blind",
      "blood",
      "blood-drop",
      "blood-test",
      "capsule",
      "crutches",
      "dna",
      "dna-alt-1",
      "doctor",
      "doctor-alt",
      "drug",
      "drug-pack",
      "eye-alt",
      "first-aid-alt",
      "garbage",
      "heart-alt",
      "heartbeat",
      "herbal",
      "hospital",
      "icu",
      "injection-syringe",
      "laboratory",
      "medical-sign",
      "medical-sign-alt",
      "nurse",
      "nurse-alt",
      "nursing-home",
      "operation-theater",
      "paralysis-disability",
      "pills",
      "prescription",
      "pulse",
      "stethoscope",
      "stethoscope-alt",
      "stretcher",
      "surgeon",
      "surgeon-alt",
      "tablets",
      "test-bottle",
      "test-tube",
      "thermometer-alt",
      "tooth",
      "xray",
    ],
    mobileui: [
      "ui-add",
      "ui-alarm",
      "ui-battery",
      "ui-block",
      "ui-bluetooth",
      "ui-brightness",
      "ui-browser",
      "ui-calculator",
      "ui-calendar",
      "ui-call",
      "ui-camera",
      "ui-cart",
      "ui-cell-phone",
      "ui-chat",
      "ui-check",
      "ui-clip",
      "ui-clip-board",
      "ui-clock",
      "ui-close",
      "ui-contact-list",
      "ui-copy",
      "ui-cut",
      "ui-delete",
      "ui-dial-phone",
      "ui-edit",
      "ui-email",
      "ui-file",
      "ui-fire-wall",
      "ui-flash-light",
      "ui-flight",
      "ui-folder",
      "ui-game",
      "ui-handicapped",
      "ui-head-phone",
      "ui-home",
      "ui-image",
      "ui-keyboard",
      "ui-laoding",
      "ui-lock",
      "ui-love",
      "ui-love-add",
      "ui-love-broken",
      "ui-love-remove",
      "ui-map",
      "ui-message",
      "ui-messaging",
      "ui-movie",
      "ui-music",
      "ui-music-player",
      "ui-mute",
      "ui-next",
      "ui-note",
      "ui-office",
      "ui-password",
      "ui-pause",
      "ui-play",
      "ui-play-stop",
      "ui-pointer",
      "ui-power",
      "ui-press",
      "ui-previous",
      "ui-rate-add",
      "ui-rate-blank",
      "ui-rate-remove",
      "ui-rating",
      "ui-record",
      "ui-remove",
      "ui-reply",
      "ui-rotation",
      "ui-rss",
      "ui-search",
      "ui-settings",
      "ui-social-link",
      "ui-tag",
      "ui-text-chat",
      "ui-text-loading",
      "ui-theme",
      "ui-timer",
      "ui-touch-phone",
      "ui-travel",
      "ui-unlock",
      "ui-user",
      "ui-user-group",
      "ui-v-card",
      "ui-video",
      "ui-video-chat",
      "ui-video-message",
      "ui-video-play",
      "ui-volume",
      "ui-weather",
      "ui-wifi",
      "ui-zoom-in",
      "ui-zoom-out",
    ],
    multimedia: [
      "cassette",
      "cassette-player",
      "forward",
      "game",
      "guiter",
      "headphone-alt-1",
      "headphone-alt-2",
      "headphone-alt-3",
      "listening",
      "megaphone",
      "megaphone-alt",
      "movie",
      "mp3-player",
      "multimedia",
      "music-disk",
      "music-note",
      "pause",
      "play-alt-1",
      "play-alt-2",
      "play-alt-3",
      "play-pause",
      "record",
      "retro-music-disk",
      "rewind",
      "song-notes",
      "sound-wave",
      "sound-wave-alt",
      "stop",
      "video-alt",
      "video-cam",
      "volume-bar",
      "volume-mute",
      "youtube-play",
    ],
    payment: [
      "amazon",
      "amazon-alt",
      "american-express",
      "american-express-alt",
      "apple-pay",
      "apple-pay-alt",
      "bank-transfer",
      "bank-transfer-alt",
      "braintree",
      "braintree-alt",
      "cash-on-delivery",
      "cash-on-delivery-alt",
      "checkout",
      "checkout-alt",
      "diners-club",
      "diners-club-alt-1",
      "diners-club-alt-2",
      "diners-club-alt-3",
      "discover",
      "discover-alt",
      "eway",
      "eway-alt",
      "google-wallet",
      "google-wallet-alt-1",
      "google-wallet-alt-2",
      "google-wallet-alt-3",
      "jcb",
      "jcb-alt",
      "maestro",
      "maestro-alt",
      "mastercard",
      "mastercard-alt",
      "payoneer",
      "payoneer-alt",
      "paypal",
      "paypal-alt",
      "sage",
      "sage-alt",
      "skrill",
      "skrill-alt",
      "stripe",
      "stripe-alt",
      "visa",
      "visa-alt",
      "visa-electron",
      "western-union",
      "western-union-alt",
    ],
    person: [
      "boy",
      "business-man",
      "business-man-alt-1",
      "business-man-alt-2",
      "business-man-alt-3",
      "funky-man",
      "girl",
      "girl-alt",
      "hotel-boy",
      "hotel-boy-alt",
      "man-in-glasses",
      "user",
      "user-alt-1",
      "user-alt-2",
      "user-alt-3",
      "user-alt-4",
      "user-alt-5",
      "user-alt-6",
      "user-alt-7",
      "user-female",
      "user-male",
      "user-suited",
      "users",
      "users-alt-1",
      "users-alt-2",
      "users-alt-3",
      "users-alt-4",
      "users-alt-5",
      "users-alt-6",
      "users-social",
      "waiter",
      "woman-in-glasses",
    ],
    search: [
      "document-search",
      "folder-search",
      "home-search",
      "job-search",
      "map-search",
      "restaurant-search",
      "search",
      "search-alt-1",
      "search-alt-2",
      "stock-search",
      "user-search",
    ],
    social: [
      "brand-drupal",
      "social-500px",
      "social-aim",
      "social-badoo",
      "social-baidu-tieba",
      "social-bbm-messenger",
      "social-bebo",
      "social-behance",
      "social-blogger",
      "social-bootstrap",
      "social-brightkite",
      "social-cloudapp",
      "social-concrete5",
      "social-delicious",
      "social-designbump",
      "social-designfloat",
      "social-deviantart",
      "social-digg",
      "social-dotcms",
      "social-dribbble",
      "social-dribble",
      "social-dropbox",
      "social-ebuddy",
      "social-ello",
      "social-ember",
      "social-evernote",
      "social-facebook",
      "social-facebook-messenger",
      "social-feedburner",
      "social-flikr",
      "social-folkd",
      "social-foursquare",
      "social-friendfeed",
      "social-ghost",
      "social-github",
      "social-gnome",
      "social-google-buzz",
      "social-google-hangouts",
      "social-google-map",
      "social-google-plus",
      "social-google-talk",
      "social-hype-machine",
      "social-instagram",
      "social-kakaotalk",
      "social-kickstarter",
      "social-kik",
      "social-kiwibox",
      "social-line",
      "social-linkedin",
      "social-linux-mint",
      "social-livejournal",
      "social-magento",
      "social-meetme",
      "social-meetup",
      "social-mixx",
      "social-newsvine",
      "social-nimbuss",
      "social-odnoklassniki",
      "social-opencart",
      "social-oscommerce",
      "social-pandora",
      "social-photobucket",
      "social-picasa",
      "social-pinterest",
      "social-prestashop",
      "social-qik",
      "social-qq",
      "social-readernaut",
      "social-reddit",
      "social-renren",
      "social-rss",
      "social-shopify",
      "social-silverstripe",
      "social-skype",
      "social-slack",
      "social-slashdot",
      "social-slidshare",
      "social-smugmug",
      "social-snapchat",
      "social-soundcloud",
      "social-spotify",
      "social-stack-exchange",
      "social-stack-overflow",
      "social-steam",
      "social-stumbleupon",
      "social-tagged",
      "social-technorati",
      "social-telegram",
      "social-tinder",
      "social-trello",
      "social-tumblr",
      "social-twitch",
      "social-twitter",
      "social-typo3",
      "social-ubercart",
      "social-viber",
      "social-viddler",
      "social-vimeo",
      "social-vine",
      "social-virb",
      "social-virtuemart",
      "social-vk",
      "social-wechat",
      "social-weibo",
      "social-whatsapp",
      "social-xing",
      "social-yahoo",
      "social-yelp",
      "social-youku",
      "social-youtube",
      "social-youtube-play",
      "social-zencart",
    ],
    sport: [
      "badminton-birdie",
      "baseball",
      "baseballer",
      "basketball",
      "basketball-hoop",
      "billiard-ball",
      "boot-alt-1",
      "boot-alt-2",
      "bowling",
      "bowling-alt",
      "canoe",
      "cheer-leader",
      "climbing",
      "corner",
      "cyclist",
      "dumbbell",
      "dumbbell-alt",
      "field",
      "field-alt",
      "football-alt",
      "foul",
      "goal",
      "goal-keeper",
      "golf",
      "golf-alt",
      "golf-bag",
      "golf-field",
      "golfer",
      "gym",
      "gym-alt-1",
      "gym-alt-2",
      "gym-alt-3",
      "hand-grippers",
      "heart-beat-alt",
      "helmet",
      "hockey",
      "hockey-alt",
      "ice-skate",
      "jersey",
      "jersey-alt",
      "jumping",
      "kick",
      "leg",
      "match-review",
      "medal-alt",
      "muscle",
      "muscle-alt",
      "offside",
      "olympic",
      "olympic-logo",
      "padding",
      "penalty-card",
      "racer",
      "racing-car",
      "racing-flag",
      "racing-flag-alt",
      "racings-wheel",
      "referee",
      "refree-jersey",
      "result",
      "rugby",
      "rugby-ball",
      "rugby-player",
      "runner",
      "runner-alt-1",
      "runner-alt-2",
      "score-board",
      "skiing-man",
      "skydiving-goggles",
      "snow-mobile",
      "steering",
      "substitute",
      "swimmer",
      "table-tennis",
      "team",
      "team-alt",
      "tennis",
      "tennis-player",
      "time",
      "track",
      "tracking",
      "trophy",
      "trophy-alt",
      "volleyball",
      "volleyball-alt",
      "volleyball-fire",
      "water-bottle",
      "whisle",
      "win-trophy",
    ],
    texteditor: [
      "align-center",
      "align-left",
      "align-right",
      "all-caps",
      "bold",
      "brush",
      "clip-board",
      "code-alt",
      "color-bucket",
      "color-picker",
      "copy-alt",
      "copy-black",
      "cut",
      "delete-alt",
      "edit-alt",
      "eraser-alt",
      "file-alt",
      "font",
      "header",
      "indent",
      "italic-alt",
      "justify-all",
      "justify-center",
      "justify-left",
      "justify-right",
      "line-height",
      "link-alt",
      "listine-dots",
      "listing-box",
      "listing-number",
      "marker",
      "outdent",
      "paper-clip",
      "paragraph",
      "pin",
      "printer",
      "redo",
      "rotation",
      "save",
      "small-cap",
      "strike-through",
      "sub-listing",
      "subscript",
      "superscript",
      "table",
      "text-height",
      "text-width",
      "trash",
      "underline",
      "undo",
      "unlink",
    ],
    transport: [
      "air-balloon",
      "airplane",
      "airplane-alt",
      "ambulance-crescent",
      "ambulance-cross",
      "articulated-truck",
      "auto-rickshaw",
      "bicycle-alt-1",
      "bicycle-alt-2",
      "bull-dozer",
      "bus-alt-1",
      "bus-alt-2",
      "bus-alt-3",
      "cable-car",
      "car-alt-1",
      "car-alt-2",
      "car-alt-3",
      "car-alt-4",
      "concrete-mixer",
      "delivery-time",
      "excavator",
      "fast-delivery",
      "fire-truck",
      "fire-truck-alt",
      "fork-lift",
      "free-delivery",
      "golf-cart",
      "helicopter",
      "motor-bike",
      "motor-bike-alt",
      "motor-biker",
      "oil-truck",
      "police-car",
      "rickshaw",
      "rocket-alt-1",
      "rocket-alt-2",
      "sail-boat",
      "scooter",
      "sea-plane",
      "ship-alt",
      "speed-boat",
      "taxi",
      "tow-truck",
      "tractor",
      "traffic-light",
      "train-line",
      "train-steam",
      "tram",
      "truck",
      "truck-alt",
      "truck-loaded",
      "van",
      "van-alt",
      "yacht",
    ],
    travel: [
      "5-star-hotel",
      "anchor-alt",
      "beach-bed",
      "camping-vest",
      "coconut-alt",
      "direction-sign",
      "hill-side",
      "island-alt",
      "long-drive",
      "map-pins",
      "plane-ticket",
      "sail-boat-alt-1",
      "sail-boat-alt-2",
      "sandals-female",
      "sandals-male",
      "travelling",
    ],
    weather: [
      "breakdown",
      "celsius",
      "clouds",
      "cloudy",
      "compass-alt",
      "dust",
      "eclipse",
      "fahrenheit",
      "forest-fire",
      "full-night",
      "full-sunny",
      "hail",
      "hail-night",
      "hail-rainy",
      "hail-rainy-night",
      "hail-rainy-sunny",
      "hail-sunny",
      "hail-thunder",
      "hail-thunder-night",
      "hail-thunder-sunny",
      "hill",
      "hill-night",
      "hill-sunny",
      "hurricane",
      "island",
      "meteor",
      "night",
      "rainy",
      "rainy-night",
      "rainy-sunny",
      "rainy-thunder",
      "showy-night-hail",
      "snow",
      "snow-temp",
      "snowy",
      "snowy-hail",
      "snowy-night",
      "snowy-night-rainy",
      "snowy-rainy",
      "snowy-sunny",
      "snowy-sunny-hail",
      "snowy-sunny-rainy",
      "snowy-thunder",
      "snowy-thunder-night",
      "snowy-thunder-sunny",
      "snowy-windy",
      "snowy-windy-night",
      "snowy-windy-sunny",
      "sun-alt",
      "sun-rise",
      "sun-set",
      "sunny",
      "sunny-day-temp",
      "thermometer",
      "thinder-light",
      "tornado",
      "umbrella-alt",
      "volcano",
      "wave",
      "wind",
      "wind-scale-0",
      "wind-scale-1",
      "wind-scale-10",
      "wind-scale-11",
      "wind-scale-12",
      "wind-scale-2",
      "wind-scale-3",
      "wind-scale-4",
      "wind-scale-5",
      "wind-scale-6",
      "wind-scale-7",
      "wind-scale-8",
      "wind-scale-9",
      "wind-waves",
      "windy",
      "windy-hail",
      "windy-night",
      "windy-raining",
      "windy-sunny",
      "windy-thunder",
      "windy-thunder-raining",
    ],
    webapplication: [
      "addons",
      "address-book",
      "adjust",
      "alarm",
      "anchor",
      "archive",
      "at",
      "attachment",
      "audio",
      "auto-mobile",
      "automation",
      "baby",
      "badge",
      "bag",
      "ban",
      "bank",
      "bar-code",
      "bars",
      "battery-empty",
      "battery-full",
      "battery-half",
      "battery-low",
      "beach",
      "beaker",
      "bear",
      "beard",
      "bed",
      "bell",
      "beverage",
      "bicycle",
      "bill",
      "bin",
      "binary",
      "binoculars",
      "bird",
      "birds",
      "black-board",
      "bluetooth",
      "bolt",
      "bomb",
      "book",
      "book-mark",
      "boot",
      "box",
      "brain",
      "briefcase",
      "broken",
      "bucket",
      "bucket1",
      "bucket2",
      "bug",
      "building",
      "bullet",
      "bullhorn",
      "bullseye",
      "bus",
      "butterfly",
      "cab",
      "calculator",
      "calendar",
      "camera",
      "camera-alt",
      "car",
      "card",
      "cart",
      "cc",
      "certificate",
      "charging",
      "chat",
      "check",
      "check-alt",
      "check-circled",
      "checked",
      "children-care",
      "clock-time",
      "close",
      "close-circled",
      "close-line",
      "close-line-circled",
      "close-line-squared",
      "close-line-squared-alt",
      "close-squared",
      "close-squared-alt",
      "cloud",
      "cloud-download",
      "cloud-refresh",
      "cloud-upload",
      "code",
      "code-not-allowed",
      "coffee-cup",
      "comment",
      "compass",
      "computer",
      "connection",
      "console",
      "contacts",
      "contrast",
      "copy",
      "copyright",
      "credit-card",
      "crop",
      "crown",
      "cube",
      "cubes",
      "culinary",
      "dashboard",
      "dashboard-web",
      "data",
      "database",
      "database-add",
      "database-locked",
      "database-remove",
      "delete",
      "diamond",
      "dice",
      "disabled",
      "disc",
      "diskette",
      "document-folder",
      "download",
      "download-alt",
      "downloaded",
      "earth",
      "ebook",
      "edit",
      "eject",
      "email",
      "envelope",
      "envelope-open",
      "eraser",
      "error",
      "exchange",
      "exclamation",
      "exclamation-circle",
      "exclamation-square",
      "exclamation-tringle",
      "exit",
      "expand",
      "external",
      "external-link",
      "eye",
      "eye-blocked",
      "eye-dropper",
      "favourite",
      "fax",
      "female",
      "file",
      "film",
      "filter",
      "fire",
      "fire-burn",
      "fire-extinguisher",
      "first-aid",
      "flag",
      "flag-alt-1",
      "flag-alt-2",
      "flash",
      "flash-light",
      "flask",
      "focus",
      "folder",
      "folder-open",
      "foot-print",
      "football",
      "football-american",
      "game-console",
      "game-pad",
      "gavel",
      "gear",
      "gears",
      "gift",
      "glass",
      "globe",
      "graduate",
      "graffiti",
      "grocery",
      "group",
      "hammer",
      "hand",
      "hanger",
      "hard-disk",
      "headphone",
      "heart",
      "heart-beat",
      "history",
      "home",
      "horn",
      "hotel",
      "hour-glass",
      "id",
      "image",
      "inbox",
      "infinite",
      "info",
      "info-circle",
      "info-square",
      "institution",
      "interface",
      "invisible",
      "italic",
      "jacket",
      "jar",
      "jewlery",
      "karate",
      "key",
      "key-hole",
      "keyboard",
      "kid",
      "label",
      "lamp",
      "laptop",
      "layers",
      "layout",
      "leaf",
      "leaflet",
      "learn",
      "legal",
      "lego",
      "lemon",
      "lens",
      "letter",
      "letterbox",
      "library",
      "license",
      "life-bouy",
      "life-buoy",
      "life-jacket",
      "life-ring",
      "light-bulb",
      "lighter",
      "lightning-ray",
      "like",
      "link",
      "live-support",
      "location-arrow",
      "location-pin",
      "lock",
      "login",
      "logout",
      "lollipop",
      "look",
      "loop",
      "luggage",
      "lunch",
      "lungs",
      "magic",
      "magic-alt",
      "magnet",
      "mail",
      "mail-box",
      "male",
      "map",
      "math",
      "maximize",
      "measure",
      "medal",
      "medical",
      "medicine",
      "mega-phone",
      "memorial",
      "memory-card",
      "mic",
      "mic-mute",
      "micro-chip",
      "microphone",
      "microscope",
      "military",
      "mill",
      "minus",
      "minus-circle",
      "minus-square",
      "mobile-phone",
      "molecule",
      "money",
      "moon",
      "mop",
      "muffin",
      "music",
      "music-alt",
      "music-notes",
      "mustache",
      "mute-volume",
      "navigation",
      "navigation-menu",
      "news",
      "newspaper",
      "no-smoking",
      "not-allowed",
      "notebook",
      "notepad",
      "notification",
      "numbered",
      "opposite",
      "optic",
      "options",
      "package",
      "page",
      "paint",
      "paper-plane",
      "paperclip",
      "papers",
      "paw",
      "pay",
      "pen",
      "pencil",
      "penguin-linux",
      "pestle",
      "phone",
      "phone-circle",
      "picture",
      "pie",
      "pine",
      "pixels",
      "play",
      "plugin",
      "plus",
      "plus-circle",
      "plus-square",
      "polygonal",
      "power",
      "presentation",
      "price",
      "print",
      "puzzle",
      "qr-code",
      "queen",
      "question",
      "question-circle",
      "question-square",
      "quote-left",
      "quote-right",
      "radio",
      "random",
      "recycle",
      "refresh",
      "repair",
      "reply",
      "reply-all",
      "resize",
      "responsive",
      "retweet",
      "road",
      "robot",
      "rocket",
      "royal",
      "rss-feed",
      "safety",
      "sale-discount",
      "satellite",
      "send-mail",
      "server",
      "settings",
      "share",
      "share-alt",
      "share-boxed",
      "shield",
      "ship",
      "shopping-cart",
      "sign-in",
      "sign-out",
      "signal",
      "site-map",
      "smart-phone",
      "soccer",
      "sort",
      "sort-alt",
      "space",
      "spanner",
      "speech-comments",
      "speed-meter",
      "spinner",
      "spinner-alt-1",
      "spinner-alt-2",
      "spinner-alt-3",
      "spinner-alt-4",
      "spinner-alt-5",
      "spinner-alt-6",
      "spreadsheet",
      "square",
      "ssl-security",
      "star",
      "star-alt-1",
      "star-alt-2",
      "street-view",
      "sun",
      "support-faq",
      "tack-pin",
      "tag",
      "tags",
      "tasks",
      "telephone",
      "telescope",
      "terminal",
      "thumbs-down",
      "thumbs-up",
      "tick-boxed",
      "tick-mark",
      "ticket",
      "tie",
      "toggle-off",
      "toggle-on",
      "tools",
      "transparent",
      "tree",
      "umbrella",
      "unlock",
      "unlocked",
      "upload",
      "upload-alt",
      "usb",
      "usb-drive",
      "vector-path",
      "verification-check",
      "video",
      "video-clapper",
      "volume-down",
      "volume-off",
      "volume-up",
      "wall",
      "wall-clock",
      "wallet",
      "warning",
      "warning-alt",
      "water-drop",
      "web",
      "wheelchair",
      "wifi",
      "wifi-alt",
      "world",
      "zigzag",
      "zipped",
    ],
  },
];

export default data;
