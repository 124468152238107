import React, { Fragment } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import { Container, Row, Col, Card, CardBody, CardHeader } from "reactstrap";
const Spinners = (props) => {
  return (
    <Fragment>
      <Breadcrumb parent="Ui Elements" title="Spinners" />
      <Container fluid={true}>
        <Row>
          <Col sn="12">
            <Card>
              <CardHeader>
                <h5>Loader Style</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 1</h6>
                    <div className="loader-box">
                      <div className="loader-1"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 2</h6>
                    <div className="loader-box">
                      <div className="loader-2"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 3</h6>
                    <div className="loader-box">
                      <div className="loader-3"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 4</h6>
                    <div className="loader-box">
                      <div className="loader-4"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 5</h6>
                    <div className="loader-box">
                      <div className="loader-5"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 6</h6>
                    <div className="loader-box">
                      <div className="loader-6"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 7</h6>
                    <div className="loader-box">
                      <div className="loader-7"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 8</h6>
                    <div className="loader-box">
                      <div className="loader-8"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 9</h6>
                    <div className="loader-box">
                      <div className="loader-9"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 10</h6>
                    <div className="loader-box">
                      <div className="loader-10"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 11</h6>
                    <div className="loader-box">
                      <div className="loader-11"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 12</h6>
                    <div className="loader-box">
                      <div className="loader-12"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 13</h6>
                    <div className="loader-box">
                      <div className="loader-15"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 14</h6>
                    <div className="loader-box">
                      <div className="loader-39"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 15</h6>
                    <div className="loader-box">
                      <div className="loader-17"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 16</h6>
                    <div className="loader-box">
                      <div className="loader-18"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 17</h6>
                    <div className="loader-box">
                      <div className="loader-19"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 18</h6>
                    <div className="loader-box">
                      <div className="loader-20"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 19</h6>
                    <div className="loader-box">
                      <div className="loader-21"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 20</h6>
                    <div className="loader-box">
                      <div className="loader-22"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 21</h6>
                    <div className="loader-box">
                      <div className="loader-23"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 22</h6>
                    <div className="loader-box">
                      <div className="loader-24"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 23</h6>
                    <div className="loader-box">
                      <div className="loader-25"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 24</h6>
                    <div className="loader-box">
                      <div className="loader-26"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 25</h6>
                    <div className="loader-box">
                      <div className="loader-29"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 26</h6>
                    <div className="loader-box">
                      <div className="loader-30"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 27</h6>
                    <div className="loader-box">
                      <div className="loader-31"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 28</h6>
                    <div className="loader-box">
                      <div className="loader-32"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 29</h6>
                    <div className="loader-box">
                      <div className="loader-34"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 30</h6>
                    <div className="loader-box">
                      <div className="loader-37"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 31</h6>
                    <div className="loader-box">
                      <div className="loader-38"></div>
                    </div>
                  </Col>
                  <Col md="3">
                    <h6 className="sub-title mb-0 text-center">Loader 32</h6>
                    <div className="loader-box">
                      <div className="loader-40"></div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Spinners;
