const data = [
  {
    typ_icons: [
      "typcn typcn-adjust-brightness",
      "typcn typcn-adjust-contrast",
      "typcn typcn-anchor-outline",
      "typcn typcn-anchor",
      "typcn typcn-archive",
      "typcn typcn-arrow-back-outline",
      "typcn typcn-arrow-back",
      "typcn typcn-arrow-down-outline",
      "typcn typcn-arrow-down-thick",
      "typcn typcn-arrow-down",
      "typcn typcn-arrow-forward-outline",
      "typcn typcn-arrow-forward",
      "typcn typcn-arrow-left-outline",
      "typcn typcn-arrow-left-thick",
      "typcn typcn-arrow-left",
      "typcn typcn-arrow-loop-outline",
      "typcn typcn-arrow-loop",
      "typcn typcn-arrow-maximise-outline",
      "typcn typcn-arrow-maximise",
      "typcn typcn-arrow-minimise-outline",
      "typcn typcn-arrow-minimise",
      "typcn typcn-arrow-move-outline",
      "typcn typcn-arrow-move",
      "typcn typcn-arrow-repeat-outline",
      "typcn typcn-arrow-repeat",
      "typcn typcn-arrow-right-outline",
      "typcn typcn-arrow-right-thick",
      "typcn typcn-arrow-right",
      "typcn typcn-arrow-shuffle",
      "typcn typcn-arrow-sorted-down",
      "typcn typcn-arrow-sorted-up",
      "typcn typcn-arrow-sync-outline",
      "typcn typcn-arrow-sync",
      "typcn typcn-arrow-unsorted",
      "typcn typcn-arrow-up-outline",
      "typcn typcn-arrow-up-thick",
      "typcn typcn-arrow-up",
      "typcn typcn-at",
      "typcn typcn-attachment-outline",
      "typcn typcn-attachment",
      "typcn typcn-backspace-outline",
      "typcn typcn-backspace",
      "typcn typcn-battery-charge",
      "typcn typcn-battery-full",
      "typcn typcn-battery-high",
      "typcn typcn-battery-low",
      "typcn typcn-battery-mid",
      "typcn typcn-beaker",
      "typcn typcn-beer",
      "typcn typcn-bell",
      "typcn typcn-book",
      "typcn typcn-bookmark",
      "typcn typcn-briefcase",
      "typcn typcn-brush",
      "typcn typcn-business-card",
      "typcn typcn-calculator",
      "typcn typcn-calendar-outline",
      "typcn typcn-calendar",
      "typcn typcn-camera-outline",
      "typcn typcn-camera",
      "typcn typcn-cancel-outline",
      "typcn typcn-cancel",
      "typcn typcn-chart-area-outline",
      "typcn typcn-chart-area",
      "typcn typcn-chart-bar-outline",
      "typcn typcn-chart-bar",
      "typcn typcn-chart-line-outline",
      "typcn typcn-chart-line",
      "typcn typcn-chart-pie-outline",
      "typcn typcn-chart-pie",
      "typcn typcn-chevron-left-outline",
      "typcn typcn-chevron-left",
      "typcn typcn-chevron-right-outline",
      "typcn typcn-chevron-right",
      "typcn typcn-clipboard",
      "typcn typcn-cloud-storage",
      "typcn typcn-cloud-storage-outline",
      "typcn typcn-code-outline",
      "typcn typcn-code",
      "typcn typcn-coffee",
      "typcn typcn-cog-outline",
      "typcn typcn-cog",
      "typcn typcn-compass",
      "typcn typcn-contacts",
      "typcn typcn-credit-card",
      "typcn typcn-css3",
      "typcn typcn-database",
      "typcn typcn-delete-outline",
      "typcn typcn-delete",
      "typcn typcn-device-desktop",
      "typcn typcn-device-laptop",
      "typcn typcn-device-phone",
      "typcn typcn-device-tablet",
      "typcn typcn-directions",
      "typcn typcn-divide-outline",
      "typcn typcn-divide",
      "typcn typcn-document-add",
      "typcn typcn-document-delete",
      "typcn typcn-document-text",
      "typcn typcn-document",
      "typcn typcn-download-outline",
      "typcn typcn-download",
      "typcn typcn-dropbox",
      "typcn typcn-edit",
      "typcn typcn-eject-outline",
      "typcn typcn-eject",
      "typcn typcn-equals-outline",
      "typcn typcn-equals",
      "typcn typcn-export-outline",
      "typcn typcn-export",
      "typcn typcn-eye-outline",
      "typcn typcn-eye",
      "typcn typcn-feather",
      "typcn typcn-film",
      "typcn typcn-filter",
      "typcn typcn-flag-outline",
      "typcn typcn-flag",
      "typcn typcn-flash-outline",
      "typcn typcn-flash",
      "typcn typcn-flow-children",
      "typcn typcn-flow-merge",
      "typcn typcn-flow-parallel",
      "typcn typcn-flow-switch",
      "typcn typcn-folder-add",
      "typcn typcn-folder-delete",
      "typcn typcn-folder-open",
      "typcn typcn-folder",
      "typcn typcn-gift",
      "typcn typcn-globe-outline",
      "typcn typcn-globe",
      "typcn typcn-group-outline",
      "typcn typcn-group",
      "typcn typcn-headphones",
      "typcn typcn-heart-full-outline",
      "typcn typcn-heart-half-outline",
      "typcn typcn-heart-outline",
      "typcn typcn-heart",
      "typcn typcn-home-outline",
      "typcn typcn-home",
      "typcn typcn-html5",
      "typcn typcn-image-outline",
      "typcn typcn-image",
      "typcn typcn-infinity-outline",
      "typcn typcn-infinity",
      "typcn typcn-info-large-outline",
      "typcn typcn-info-large",
      "typcn typcn-info-outline",
      "typcn typcn-info",
      "typcn typcn-input-checked-outline",
      "typcn typcn-input-checked",
      "typcn typcn-key-outline",
      "typcn typcn-key",
      "typcn typcn-keyboard",
      "typcn typcn-leaf",
      "typcn typcn-lightbulb",
      "typcn typcn-link-outline",
      "typcn typcn-link",
      "typcn typcn-location-arrow-outline",
      "typcn typcn-location-arrow",
      "typcn typcn-location-outline",
      "typcn typcn-location",
      "typcn typcn-lock-closed-outline",
      "typcn typcn-lock-closed",
      "typcn typcn-lock-open-outline",
      "typcn typcn-lock-open",
      "typcn typcn-mail",
      "typcn typcn-map",
      "typcn typcn-media-eject-outline",
      "typcn typcn-media-eject",
      "typcn typcn-media-fast-forward-outline",
      "typcn typcn-media-fast-forward",
      "typcn typcn-media-pause-outline",
      "typcn typcn-media-pause",
      "typcn typcn-media-play-outline",
      "typcn typcn-media-play-reverse-outline",
      "typcn typcn-media-play-reverse",
      "typcn typcn-media-play",
      "typcn typcn-media-record-outline",
      "typcn typcn-media-record",
      "typcn typcn-media-rewind-outline",
      "typcn typcn-media-rewind",
      "typcn typcn-media-stop-outline",
      "typcn typcn-media-stop",
      "typcn typcn-message-typing",
      "typcn typcn-message",
      "typcn typcn-messages",
      "typcn typcn-microphone-outline",
      "typcn typcn-microphone",
      "typcn typcn-minus-outline",
      "typcn typcn-minus",
      "typcn typcn-mortar-board",
      "typcn typcn-news",
      "typcn typcn-notes-outline",
      "typcn typcn-notes",
      "typcn typcn-pen",
      "typcn typcn-pencil",
      "typcn typcn-phone-outline",
      "typcn typcn-phone",
      "typcn typcn-pi-outline",
      "typcn typcn-pi",
      "typcn typcn-pin-outline",
      "typcn typcn-pin",
      "typcn typcn-pipette",
      "typcn typcn-plane-outline",
      "typcn typcn-plane",
      "typcn typcn-plug",
      "typcn typcn-plus-outline",
      "typcn typcn-plus",
      "typcn typcn-point-of-interest-outline",
      "typcn typcn-point-of-interest",
      "typcn typcn-power-outline",
      "typcn typcn-power",
      "typcn typcn-printer",
      "typcn typcn-puzzle-outline",
      "typcn typcn-puzzle",
      "typcn typcn-radar-outline",
      "typcn typcn-radar",
      "typcn typcn-refresh-outline",
      "typcn typcn-refresh",
      "typcn typcn-rss-outline",
      "typcn typcn-rss",
      "typcn typcn-scissors-outline",
      "typcn typcn-scissors",
      "typcn typcn-shopping-bag",
      "typcn typcn-shopping-cart",
      "typcn typcn-social-at-circular",
      "typcn typcn-social-dribbble-circular",
      "typcn typcn-social-dribbble",
      "typcn typcn-social-facebook-circular",
      "typcn typcn-social-facebook",
      "typcn typcn-social-flickr-circular",
      "typcn typcn-social-flickr",
      "typcn typcn-social-github-circular",
      "typcn typcn-social-github",
      "typcn typcn-social-google-plus-circular",
      "typcn typcn-social-google-plus",
      "typcn typcn-social-instagram-circular",
      "typcn typcn-social-instagram",
      "typcn typcn-social-last-fm-circular",
      "typcn typcn-social-last-fm",
      "typcn typcn-social-linkedin-circular",
      "typcn typcn-social-linkedin",
      "typcn typcn-social-pinterest-circular",
      "typcn typcn-social-pinterest",
      "typcn typcn-social-skype-outline",
      "typcn typcn-social-skype",
      "typcn typcn-social-tumbler-circular",
      "typcn typcn-social-tumbler",
      "typcn typcn-social-twitter-circular",
      "typcn typcn-social-twitter",
      "typcn typcn-social-vimeo-circular",
      "typcn typcn-social-vimeo",
      "typcn typcn-social-youtube-circular",
      "typcn typcn-social-youtube",
      "typcn typcn-sort-alphabetically-outline",
      "typcn typcn-sort-alphabetically",
      "typcn typcn-sort-numerically-outline",
      "typcn typcn-sort-numerically",
      "typcn typcn-spanner-outline",
      "typcn typcn-spanner",
      "typcn typcn-spiral",
      "typcn typcn-star-full-outline",
      "typcn typcn-star-half-outline",
      "typcn typcn-star-half",
      "typcn typcn-star-outline",
      "typcn typcn-star",
      "typcn typcn-starburst-outline",
      "typcn typcn-starburst",
      "typcn typcn-stopwatch",
      "typcn typcn-support",
      "typcn typcn-tabs-outline",
      "typcn typcn-tag",
      "typcn typcn-tags",
      "typcn typcn-th-large-outline",
      "typcn typcn-th-large",
      "typcn typcn-th-list-outline",
      "typcn typcn-th-list",
      "typcn typcn-th-menu-outline",
      "typcn typcn-th-menu",
      "typcn typcn-th-small-outline",
      "typcn typcn-th-small",
      "typcn typcn-thermometer",
      "typcn typcn-thumbs-down",
      "typcn typcn-thumbs-ok",
      "typcn typcn-thumbs-up",
      "typcn typcn-tick-outline",
      "typcn typcn-tick",
      "typcn typcn-ticket",
      "typcn typcn-time",
      "typcn typcn-times-outline",
      "typcn typcn-times",
      "typcn typcn-trash",
      "typcn typcn-tree",
      "typcn typcn-upload-outline",
      "typcn typcn-upload",
      "typcn typcn-user-add-outline",
      "typcn typcn-user-add",
      "typcn typcn-user-delete-outline",
      "typcn typcn-user-delete",
      "typcn typcn-user-outline",
      "typcn typcn-user",
      "typcn typcn-vendor-android",
      "typcn typcn-vendor-apple",
      "typcn typcn-vendor-microsoft",
      "typcn typcn-video-outline",
      "typcn typcn-video",
      "typcn typcn-volume-down",
      "typcn typcn-volume-mute",
      "typcn typcn-volume-up",
      "typcn typcn-volume",
      "typcn typcn-warning-outline",
      "typcn typcn-warning",
      "typcn typcn-watch",
      "typcn typcn-waves-outline",
      "typcn typcn-waves",
      "typcn typcn-weather-cloudy",
      "typcn typcn-weather-downpour",
      "typcn typcn-weather-night",
      "typcn typcn-weather-partly-sunny",
      "typcn typcn-weather-shower",
      "typcn typcn-weather-snow",
      "typcn typcn-weather-stormy",
      "typcn typcn-weather-sunny",
      "typcn typcn-weather-windy-cloudy",
      "typcn typcn-weather-windy",
      "typcn typcn-wi-fi-outline",
      "typcn typcn-wi-fi",
      "typcn typcn-wine",
      "typcn typcn-world-outline",
      "typcn typcn-world",
      "typcn typcn-zoom-in-outline",
      "typcn typcn-zoom-in",
      "typcn typcn-zoom-out-outline",
      "typcn typcn-zoom-out",
      "typcn typcn-zoom-outline",
      "typcn typcn-zoom",
    ],
  },
];
export default data;
