const data = [
  {
    ionic_icon: [
      "ion ion-ionic",
      "ion ion-arrow-up-a",
      "ion ion-arrow-right-a",
      "ion ion-arrow-down-a",
      "ion ion-arrow-left-a",
      "ion ion-arrow-up-b",
      "ion ion-arrow-right-b",
      "ion ion-arrow-down-b",
      "ion ion-arrow-left-b",
      "ion ion-arrow-up-c",
      "ion ion-arrow-right-c",
      "ion ion-arrow-down-c",
      "ion ion-arrow-left-c",
      "ion ion-arrow-return-right",
      "ion ion-arrow-return-left",
      "ion ion-arrow-swap",
      "ion ion-arrow-shrink",
      "ion ion-arrow-expand",
      "ion ion-arrow-move",
      "ion ion-arrow-resize",
      "ion ion-chevron-up",
      "ion ion-chevron-right",
      "ion ion-chevron-down",
      "ion ion-chevron-left",
      "ion ion-navicon-round",
      "ion ion-navicon",
      "ion ion-drag",
      "ion ion-log-in",
      "ion ion-log-out",
      "ion ion-checkmark-round",
      "ion ion-checkmark",
      "ion ion-checkmark-circled",
      "ion ion-close-round",
      "ion ion-close",
      "ion ion-close-circled",
      "ion ion-plus-round",
      "ion ion-plus",
      "ion ion-plus-circled",
      "ion ion-minus-round",
      "ion ion-minus",
      "ion ion-minus-circled",
      "ion ion-information",
      "ion ion-information-circled",
      "ion ion-help",
      "ion ion-help-circled",
      "ion ion-backspace-outline",
      "ion ion-backspace",
      "ion ion-help-buoy",
      "ion ion-asterisk",
      "ion ion-alert",
      "ion ion-alert-circled",
      "ion ion-refresh",
      "ion ion-loop",
      "ion ion-shuffle",
      "ion ion-home",
      "ion ion-search",
      "ion ion-flag",
      "ion ion-star",
      "ion ion-heart",
      "ion ion-heart-broken",
      "ion ion-gear-a",
      "ion ion-gear-b",
      "ion ion-toggle-filled",
      "ion ion-toggle",
      "ion ion-settings",
      "ion ion-wrench",
      "ion ion-hammer",
      "ion ion-edit",
      "ion ion-trash-a",
      "ion ion-trash-b",
      "ion ion-document",
      "ion ion-document-text",
      "ion ion-clipboard",
      "ion ion-scissors",
      "ion ion-funnel",
      "ion ion-bookmark",
      "ion ion-email",
      "ion ion-email-unread",
      "ion ion-folder",
      "ion ion-filing",
      "ion ion-archive",
      "ion ion-reply",
      "ion ion-reply-all",
      "ion ion-forward",
      "ion ion-share",
      "ion ion-paper-airplane",
      "ion ion-link",
      "ion ion-paperclip",
      "ion ion-compose",
      "ion ion-briefcase",
      "ion ion-medkit",
      "ion ion-at",
      "ion ion-pound",
      "ion ion-quote",
      "ion ion-cloud",
      "ion ion-upload",
      "ion ion-more",
      "ion ion-grid",
      "ion ion-calendar",
      "ion ion-clock",
      "ion ion-compass",
      "ion ion-pinpoint",
      "ion ion-pin",
      "ion ion-navigate",
      "ion ion-location",
      "ion ion-map",
      "ion ion-lock-combination",
      "ion ion-locked",
      "ion ion-unlocked",
      "ion ion-key",
      "ion ion-arrow-graph-up-right",
      "ion ion-arrow-graph-down-right",
      "ion ion-arrow-graph-up-left",
      "ion ion-arrow-graph-down-left",
      "ion ion-stats-bars",
      "ion ion-connection-bars",
      "ion ion-pie-graph",
      "ion ion-chatbubble",
      "ion ion-chatbubble-working",
      "ion ion-chatbubbles",
      "ion ion-chatbox",
      "ion ion-chatbox-working",
      "ion ion-chatboxes",
      "ion ion-person",
      "ion ion-person-add",
      "ion ion-person-stalker",
      "ion ion-woman",
      "ion ion-man",
      "ion ion-female",
      "ion ion-male",
      "ion ion-transgender",
      "ion ion-fork",
      "ion ion-knife",
      "ion ion-spoon",
      "ion ion-soup-can-outline",
      "ion ion-soup-can",
      "ion ion-beer",
      "ion ion-wineglass",
      "ion ion-coffee",
      "ion ion-icecream",
      "ion ion-pizza",
      "ion ion-power",
      "ion ion-mouse",
      "ion ion-battery-full",
      "ion ion-battery-half",
      "ion ion-battery-low",
      "ion ion-battery-empty",
      "ion ion-battery-charging",
      "ion ion-wifi",
      "ion ion-bluetooth",
      "ion ion-calculator",
      "ion ion-camera",
      "ion ion-eye",
      "ion ion-eye-disabled",
      "ion ion-flash",
      "ion ion-flash-off",
      "ion ion-qr-scanner",
      "ion ion-image",
      "ion ion-images",
      "ion ion-wand",
      "ion ion-contrast",
      "ion ion-aperture",
      "ion ion-crop",
      "ion ion-easel",
      "ion ion-paintbrush",
      "ion ion-paintbucket",
      "ion ion-monitor",
      "ion ion-laptop",
      "ion ion-ipad",
      "ion ion-iphone",
      "ion ion-ipod",
      "ion ion-printer",
      "ion ion-usb",
      "ion ion-outlet",
      "ion ion-bug",
      "ion ion-code",
      "ion ion-code-working",
      "ion ion-code-download",
      "ion ion-fork-repo",
      "ion ion-network",
      "ion ion-pull-request",
      "ion ion-merge",
      "ion ion-xbox",
      "ion ion-playstation",
      "ion ion-steam",
      "ion ion-closed-captioning",
      "ion ion-videocamera",
      "ion ion-film-marker",
      "ion ion-disc",
      "ion ion-headphone",
      "ion ion-music-note",
      "ion ion-radio-waves",
      "ion ion-speakerphone",
      "ion ion-mic-a",
      "ion ion-mic-b",
      "ion ion-mic-c",
      "ion ion-volume-high",
      "ion ion-volume-medium",
      "ion ion-volume-low",
      "ion ion-volume-mute",
      "ion ion-levels",
      "ion ion-play",
      "ion ion-pause",
      "ion ion-stop",
      "ion ion-record",
      "ion ion-skip-forward",
      "ion ion-skip-backward",
      "ion ion-eject",
      "ion ion-bag",
      "ion ion-card",
      "ion ion-cash",
      "ion ion-pricetag",
      "ion ion-pricetags",
      "ion ion-thumbsup",
      "ion ion-thumbsdown",
      "ion ion-happy-outline",
      "ion ion-happy",
      "ion ion-sad-outline",
      "ion ion-sad",
      "ion ion-bowtie",
      "ion ion-tshirt-outline",
      "ion ion-tshirt",
      "ion ion-trophy",
      "ion ion-podium",
      "ion ion-ribbon-a",
      "ion ion-ribbon-b",
      "ion ion-university",
      "ion ion-magnet",
      "ion ion-beaker",
      "ion ion-erlenmeyer-flask",
      "ion ion-egg",
      "ion ion-earth",
      "ion ion-planet",
      "ion ion-lightbulb",
      "ion ion-cube",
      "ion ion-leaf",
      "ion ion-waterdrop",
      "ion ion-flame",
      "ion ion-fireball",
      "ion ion-bonfire",
      "ion ion-umbrella",
      "ion ion-nuclear",
      "ion ion-no-smoking",
      "ion ion-thermometer",
      "ion ion-speedometer",
      "ion ion-model-s",
      "ion ion-plane",
      "ion ion-jet",
      "ion ion-load-a",
      "ion ion-load-b",
      "ion ion-load-c",
      "ion ion-load-d",
      "ion ion-ios-ionic-outline",
      "ion ion-ios-arrow-back",
      "ion ion-ios-arrow-forward",
      "ion ion-ios-arrow-up",
      "ion ion-ios-arrow-right",
      "ion ion-ios-arrow-down",
      "ion ion-ios-arrow-left",
      "ion ion-ios-arrow-thin-up",
      "ion ion-ios-arrow-thin-right",
      "ion ion-ios-arrow-thin-down",
      "ion ion-ios-arrow-thin-left",
      "ion ion-ios-circle-filled",
      "ion ion-ios-circle-outline",
      "ion ion-ios-checkmark-empty",
      "ion ion-ios-checkmark-outline",
      "ion ion-ios-checkmark",
      "ion ion-ios-plus-empty",
      "ion ion-ios-plus-outline",
      "ion ion-ios-plus",
      "ion ion-ios-close-empty",
      "ion ion-ios-close-outline",
      "ion ion-ios-close",
      "ion ion-ios-minus-empty",
      "ion ion-ios-minus-outline",
      "ion ion-ios-minus",
      "ion ion-ios-information-empty",
      "ion ion-ios-information-outline",
      "ion ion-ios-information",
      "ion ion-ios-help-empty",
      "ion ion-ios-help-outline",
      "ion ion-ios-help",
      "ion ion-ios-search",
      "ion ion-ios-search-strong",
      "ion ion-ios-star",
      "ion ion-ios-star-half",
      "ion ion-ios-star-outline",
      "ion ion-ios-heart",
      "ion ion-ios-heart-outline",
      "ion ion-ios-more",
      "ion ion-ios-more-outline",
      "ion ion-ios-home",
      "ion ion-ios-home-outline",
      "ion ion-ios-cloud",
      "ion ion-ios-cloud-outline",
      "ion ion-ios-cloud-upload",
      "ion ion-ios-cloud-upload-outline",
      "ion ion-ios-cloud-download",
      "ion ion-ios-cloud-download-outline",
      "ion ion-ios-upload",
      "ion ion-ios-upload-outline",
      "ion ion-ios-download",
      "ion ion-ios-download-outline",
      "ion ion-ios-refresh",
      "ion ion-ios-refresh-outline",
      "ion ion-ios-refresh-empty",
      "ion ion-ios-reload",
      "ion ion-ios-loop-strong",
      "ion ion-ios-loop",
      "ion ion-ios-bookmarks",
      "ion ion-ios-bookmarks-outline",
      "ion ion-ios-book",
      "ion ion-ios-book-outline",
      "ion ion-ios-flag",
      "ion ion-ios-flag-outline",
      "ion ion-ios-glasses",
      "ion ion-ios-glasses-outline",
      "ion ion-ios-browsers",
      "ion ion-ios-browsers-outline",
      "ion ion-ios-at",
      "ion ion-ios-at-outline",
      "ion ion-ios-pricetag",
      "ion ion-ios-pricetag-outline",
      "ion ion-ios-pricetags",
      "ion ion-ios-pricetags-outline",
      "ion ion-ios-cart",
      "ion ion-ios-cart-outline",
      "ion ion-ios-chatboxes",
      "ion ion-ios-chatboxes-outline",
      "ion ion-ios-chatbubble",
      "ion ion-ios-chatbubble-outline",
      "ion ion-ios-cog",
      "ion ion-ios-cog-outline",
      "ion ion-ios-gear",
      "ion ion-ios-gear-outline",
      "ion ion-ios-settings",
      "ion ion-ios-settings-strong",
      "ion ion-ios-toggle",
      "ion ion-ios-toggle-outline",
      "ion ion-ios-analytics",
      "ion ion-ios-analytics-outline",
      "ion ion-ios-pie",
      "ion ion-ios-pie-outline",
      "ion ion-ios-pulse",
      "ion ion-ios-pulse-strong",
      "ion ion-ios-filing",
      "ion ion-ios-filing-outline",
      "ion ion-ios-box",
      "ion ion-ios-box-outline",
      "ion ion-ios-compose",
      "ion ion-ios-compose-outline",
      "ion ion-ios-trash",
      "ion ion-ios-trash-outline",
      "ion ion-ios-copy",
      "ion ion-ios-copy-outline",
      "ion ion-ios-email",
      "ion ion-ios-email-outline",
      "ion ion-ios-undo",
      "ion ion-ios-undo-outline",
      "ion ion-ios-redo",
      "ion ion-ios-redo-outline",
      "ion ion-ios-paperplane",
      "ion ion-ios-paperplane-outline",
      "ion ion-ios-folder",
      "ion ion-ios-folder-outline",
      "ion ion-ios-paper",
      "ion ion-ios-paper-outline",
      "ion ion-ios-list",
      "ion ion-ios-list-outline",
      "ion ion-ios-world",
      "ion ion-ios-world-outline",
      "ion ion-ios-alarm",
      "ion ion-ios-alarm-outline",
      "ion ion-ios-speedometer",
      "ion ion-ios-speedometer-outline",
      "ion ion-ios-stopwatch",
      "ion ion-ios-stopwatch-outline",
      "ion ion-ios-timer",
      "ion ion-ios-timer-outline",
      "ion ion-ios-clock",
      "ion ion-ios-clock-outline",
      "ion ion-ios-time",
      "ion ion-ios-time-outline",
      "ion ion-ios-calendar",
      "ion ion-ios-calendar-outline",
      "ion ion-ios-photos",
      "ion ion-ios-photos-outline",
      "ion ion-ios-albums",
      "ion ion-ios-albums-outline",
      "ion ion-ios-camera",
      "ion ion-ios-camera-outline",
      "ion ion-ios-reverse-camera",
      "ion ion-ios-reverse-camera-outline",
      "ion ion-ios-eye",
      "ion ion-ios-eye-outline",
      "ion ion-ios-bolt",
      "ion ion-ios-bolt-outline",
      "ion ion-ios-color-wand",
      "ion ion-ios-color-wand-outline",
      "ion ion-ios-color-filter",
      "ion ion-ios-color-filter-outline",
      "ion ion-ios-grid-view",
      "ion ion-ios-grid-view-outline",
      "ion ion-ios-crop-strong",
      "ion ion-ios-crop",
      "ion ion-ios-barcode",
      "ion ion-ios-barcode-outline",
      "ion ion-ios-briefcase",
      "ion ion-ios-briefcase-outline",
      "ion ion-ios-medkit",
      "ion ion-ios-medkit-outline",
      "ion ion-ios-medical",
      "ion ion-ios-medical-outline",
      "ion ion-ios-infinite",
      "ion ion-ios-infinite-outline",
      "ion ion-ios-calculator",
      "ion ion-ios-calculator-outline",
      "ion ion-ios-keypad",
      "ion ion-ios-keypad-outline",
      "ion ion-ios-telephone",
      "ion ion-ios-telephone-outline",
      "ion ion-ios-drag",
      "ion ion-ios-location",
      "ion ion-ios-location-outline",
      "ion ion-ios-navigate",
      "ion ion-ios-navigate-outline",
      "ion ion-ios-locked",
      "ion ion-ios-locked-outline",
      "ion ion-ios-unlocked",
      "ion ion-ios-unlocked-outline",
      "ion ion-ios-monitor",
      "ion ion-ios-monitor-outline",
      "ion ion-ios-printer",
      "ion ion-ios-printer-outline",
      "ion ion-ios-game-controller-a",
      "ion ion-ios-game-controller-a-outline",
      "ion ion-ios-game-controller-b",
      "ion ion-ios-game-controller-b-outline",
      "ion ion-ios-americanfootball",
      "ion ion-ios-americanfootball-outline",
      "ion ion-ios-baseball",
      "ion ion-ios-baseball-outline",
      "ion ion-ios-basketball",
      "ion ion-ios-basketball-outline",
      "ion ion-ios-tennisball",
      "ion ion-ios-tennisball-outline",
      "ion ion-ios-football",
      "ion ion-ios-football-outline",
      "ion ion-ios-body",
      "ion ion-ios-body-outline",
      "ion ion-ios-person",
      "ion ion-ios-person-outline",
      "ion ion-ios-personadd",
      "ion ion-ios-personadd-outline",
      "ion ion-ios-people",
      "ion ion-ios-people-outline",
      "ion ion-ios-musical-notes",
      "ion ion-ios-musical-note",
      "ion ion-ios-bell",
      "ion ion-ios-bell-outline",
      "ion ion-ios-mic",
      "ion ion-ios-mic-outline",
      "ion ion-ios-mic-off",
      "ion ion-ios-volume-high",
      "ion ion-ios-volume-low",
      "ion ion-ios-play",
      "ion ion-ios-play-outline",
      "ion ion-ios-pause",
      "ion ion-ios-pause-outline",
      "ion ion-ios-recording",
      "ion ion-ios-recording-outline",
      "ion ion-ios-fastforward",
      "ion ion-ios-fastforward-outline",
      "ion ion-ios-rewind",
      "ion ion-ios-rewind-outline",
      "ion ion-ios-skipbackward",
      "ion ion-ios-skipbackward-outline",
      "ion ion-ios-skipforward",
      "ion ion-ios-skipforward-outline",
      "ion ion-ios-shuffle-strong",
      "ion ion-ios-shuffle",
      "ion ion-ios-videocam",
      "ion ion-ios-videocam-outline",
      "ion ion-ios-film",
      "ion ion-ios-film-outline",
      "ion ion-ios-flask",
      "ion ion-ios-flask-outline",
      "ion ion-ios-lightbulb",
      "ion ion-ios-lightbulb-outline",
      "ion ion-ios-wineglass",
      "ion ion-ios-wineglass-outline",
      "ion ion-ios-pint",
      "ion ion-ios-pint-outline",
      "ion ion-ios-nutrition",
      "ion ion-ios-nutrition-outline",
      "ion ion-ios-flower",
      "ion ion-ios-flower-outline",
      "ion ion-ios-rose",
      "ion ion-ios-rose-outline",
      "ion ion-ios-paw",
      "ion ion-ios-paw-outline",
      "ion ion-ios-flame",
      "ion ion-ios-flame-outline",
      "ion ion-ios-sunny",
      "ion ion-ios-sunny-outline",
      "ion ion-ios-partlysunny",
      "ion ion-ios-partlysunny-outline",
      "ion ion-ios-cloudy",
      "ion ion-ios-cloudy-outline",
      "ion ion-ios-rainy",
      "ion ion-ios-rainy-outline",
      "ion ion-ios-thunderstorm",
      "ion ion-ios-thunderstorm-outline",
      "ion ion-ios-snowy",
      "ion ion-ios-moon",
      "ion ion-ios-moon-outline",
      "ion ion-ios-cloudy-night",
      "ion ion-ios-cloudy-night-outline",
      "ion ion-android-arrow-up",
      "ion ion-android-arrow-forward",
      "ion ion-android-arrow-down",
      "ion ion-android-arrow-back",
      "ion ion-android-arrow-dropup",
      "ion ion-android-arrow-dropup-circle",
      "ion ion-android-arrow-dropright",
      "ion ion-android-arrow-dropright-circle",
      "ion ion-android-arrow-dropdown",
      "ion ion-android-arrow-dropdown-circle",
      "ion ion-android-arrow-dropleft",
      "ion ion-android-arrow-dropleft-circle",
      "ion ion-android-add",
      "ion ion-android-add-circle",
      "ion ion-android-remove",
      "ion ion-android-remove-circle",
      "ion ion-android-close",
      "ion ion-android-cancel",
      "ion ion-android-radio-button-off",
      "ion ion-android-radio-button-on",
      "ion ion-android-checkmark-circle",
      "ion ion-android-checkbox-outline-blank",
      "ion ion-android-checkbox-outline",
      "ion ion-android-checkbox-blank",
      "ion ion-android-checkbox",
      "ion ion-android-done",
      "ion ion-android-done-all",
      "ion ion-android-menu",
      "ion ion-android-more-horizontal",
      "ion ion-android-more-vertical",
      "ion ion-android-refresh",
      "ion ion-android-sync",
      "ion ion-android-wifi",
      "ion ion-android-call",
      "ion ion-android-apps",
      "ion ion-android-settings",
      "ion ion-android-options",
      "ion ion-android-funnel",
      "ion ion-android-search",
      "ion ion-android-home",
      "ion ion-android-cloud-outline",
      "ion ion-android-cloud",
      "ion ion-android-download",
      "ion ion-android-upload",
      "ion ion-android-cloud-done",
      "ion ion-android-cloud-circle",
      "ion ion-android-favorite-outline",
      "ion ion-android-favorite",
      "ion ion-android-star-outline",
      "ion ion-android-star-half",
      "ion ion-android-star",
      "ion ion-android-calendar",
      "ion ion-android-alarm-clock",
      "ion ion-android-time",
      "ion ion-android-stopwatch",
      "ion ion-android-watch",
      "ion ion-android-locate",
      "ion ion-android-navigate",
      "ion ion-android-pin",
      "ion ion-android-compass",
      "ion ion-android-map",
      "ion ion-android-walk",
      "ion ion-android-bicycle",
      "ion ion-android-car",
      "ion ion-android-bus",
      "ion ion-android-subway",
      "ion ion-android-train",
      "ion ion-android-boat",
      "ion ion-android-plane",
      "ion ion-android-restaurant",
      "ion ion-android-bar",
      "ion ion-android-cart",
      "ion ion-android-camera",
      "ion ion-android-image",
      "ion ion-android-film",
      "ion ion-android-color-palette",
      "ion ion-android-create",
      "ion ion-android-mail",
      "ion ion-android-drafts",
      "ion ion-android-send",
      "ion ion-android-archive",
      "ion ion-android-delete",
      "ion ion-android-attach",
      "ion ion-android-share",
      "ion ion-android-share-alt",
      "ion ion-android-bookmark",
      "ion ion-android-document",
      "ion ion-android-clipboard",
      "ion ion-android-list",
      "ion ion-android-folder-open",
      "ion ion-android-folder",
      "ion ion-android-print",
      "ion ion-android-open",
      "ion ion-android-exit",
      "ion ion-android-contract",
      "ion ion-android-expand",
      "ion ion-android-globe",
      "ion ion-android-chat",
      "ion ion-android-textsms",
      "ion ion-android-hangout",
      "ion ion-android-happy",
      "ion ion-android-sad",
      "ion ion-android-person",
      "ion ion-android-people",
      "ion ion-android-person-add",
      "ion ion-android-contact",
      "ion ion-android-contacts",
      "ion ion-android-playstore",
      "ion ion-android-lock",
      "ion ion-android-unlock",
      "ion ion-android-microphone",
      "ion ion-android-microphone-off",
      "ion ion-android-notifications-none",
      "ion ion-android-notifications",
      "ion ion-android-notifications-off",
      "ion ion-android-volume-mute",
      "ion ion-android-volume-down",
      "ion ion-android-volume-up",
      "ion ion-android-hand",
      "ion ion-android-desktop",
      "ion ion-android-laptop",
      "ion ion-android-phone-portrait",
      "ion ion-android-phone-landscape",
      "ion ion-android-bulb",
      "ion ion-android-sunny",
      "ion ion-android-alert",
      "ion ion-android-warning",
      "ion ion-social-twitter",
      "ion ion-social-twitter-outline",
      "ion ion-social-facebook",
      "ion ion-social-facebook-outline",
      "ion ion-social-googleplus",
      "ion ion-social-googleplus-outline",
      "ion ion-social-google",
      "ion ion-social-google-outline",
      "ion ion-social-dribbble",
      "ion ion-social-dribbble-outline",
      "ion ion-social-octocat",
      "ion ion-social-github",
      "ion ion-social-github-outline",
      "ion ion-social-instagram",
      "ion ion-social-instagram-outline",
      "ion ion-social-whatsapp",
      "ion ion-social-whatsapp-outline",
      "ion ion-social-snapchat",
      "ion ion-social-snapchat-outline",
      "ion ion-social-foursquare",
      "ion ion-social-foursquare-outline",
      "ion ion-social-pinterest",
      "ion ion-social-pinterest-outline",
      "ion ion-social-rss",
      "ion ion-social-rss-outline",
      "ion ion-social-tumblr",
      "ion ion-social-tumblr-outline",
      "ion ion-social-wordpress",
      "ion ion-social-wordpress-outline",
      "ion ion-social-reddit",
      "ion ion-social-reddit-outline",
      "ion ion-social-hackernews",
      "ion ion-social-hackernews-outline",
      "ion ion-social-designernews",
      "ion ion-social-designernews-outline",
      "ion ion-social-yahoo",
      "ion ion-social-yahoo-outline",
      "ion ion-social-buffer",
      "ion ion-social-buffer-outline",
      "ion ion-social-skype",
      "ion ion-social-skype-outline",
      "ion ion-social-linkedin",
      "ion ion-social-linkedin-outline",
      "ion ion-social-vimeo",
      "ion ion-social-vimeo-outline",
      "ion ion-social-twitch",
      "ion ion-social-twitch-outline",
      "ion ion-social-youtube",
      "ion ion-social-youtube-outline",
      "ion ion-social-dropbox",
      "ion ion-social-dropbox-outline",
      "ion ion-social-apple",
      "ion ion-social-apple-outline",
      "ion ion-social-android",
      "ion ion-social-android-outline",
      "ion ion-social-windows",
      "ion ion-social-windows-outline",
      "ion ion-social-html5",
      "ion ion-social-html5-outline",
      "ion ion-social-css3",
      "ion ion-social-css3-outline",
      "ion ion-social-javascript",
      "ion ion-social-javascript-outline",
      "ion ion-social-angular",
      "ion ion-social-angular-outline",
      "ion ion-social-nodejs",
      "ion ion-social-sass",
      "ion ion-social-python",
      "ion ion-social-chrome",
      "ion ion-social-chrome-outline",
      "ion ion-social-codepen",
      "ion ion-social-codepen-outline",
      "ion ion-social-markdown",
      "ion ion-social-tux",
      "ion ion-social-freebsd-devil",
      "ion ion-social-usd",
      "ion ion-social-usd-outline",
      "ion ion-social-bitcoin",
      "ion ion-social-bitcoin-outline",
      "ion ion-social-yen",
      "ion ion-social-yen-outline",
      "ion ion-social-euro",
      "ion ion-social-euro-outline",
    ],
  },
];
export default data;
