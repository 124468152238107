export const sticky = [
  {
    id: 1,
    isDeleted: false,
  },
  {
    id: 2,
    isDeleted: false,
  },
  {
    id: 3,
    isDeleted: false,
  },
  {
    id: 4,
    isDeleted: false,
  },
];

export default sticky;
